import React from "react";

interface BuscarCampoProps {
 searchTerm: string;
 onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
 onOptionSelect: (descricao: string) => void;
 filteredOptions: { codigo: string; descricao: string }[];
 placeholder?: string;
}

const BuscarCampo: React.FC<BuscarCampoProps> = ({
 searchTerm,
 onSearchChange,
 onOptionSelect,
 filteredOptions,
 placeholder = "Digite para pesquisar...",
}) => {
 const [showOptions, setShowOptions] = React.useState(false);

 return (
  <div className="position-relative">
   <input
    type="text"
    style={{ height: "45px" }}
    className="form-control"
    placeholder={placeholder}
    value={searchTerm}
    onChange={onSearchChange}
    onFocus={() => setShowOptions(true)}
    onBlur={() => setTimeout(() => setShowOptions(false), 200)}
   />
   {showOptions && searchTerm.length >= 3 && (
    <ul
     className="list-group position-absolute w-100"
     style={{
      maxHeight: "400px",
      overflowY: "auto",
      zIndex: 1000,
     }}
    >
     {filteredOptions.length > 0 ? (
      filteredOptions.map((item) => (
       <li
        key={item.codigo}
        className="list-group-item list-group-item-action"
        onMouseDown={() => onOptionSelect(item.descricao)}
       >
        {`${item.codigo} - ${item.descricao.length >= 150
         ? item.descricao.substring(0, 150)
         : item.descricao
         }`}
       </li>
      ))
     ) : (
      <li className="list-group-item">Nenhuma opção encontrada</li>
     )}
    </ul>
   )}
  </div>
 );
};

export default BuscarCampo;
