import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { TabelaOrcamentos, TabelaQuantitativos } from "../../components";
import Anexos from "../../components/Anexos";
import BuscarCampo from "../../components/BuscarCampos";
import OpcaoInsumoComposicao from "../../components/CheckboxesInsumoComposicao";
import DataAssinatura from "../../components/DataAssinatura";
import DetalhesPedido from "../../components/DetalhesPedido";
import Formulario from "../../components/Formulario";
import ItemSemOrcamento from "../../components/ItemSemOrcamento";
import Navbar from "../../components/Navbar";
import useDetalhesDoPedido from "../../Hooks/useDetalhesDoPedido";
import useHistoricoDoPedido from "../../Hooks/useHistoricoPedido";
import { usePermissao } from "../../Hooks/usePermissao";
import useServicosComposicoesInsumos from "../../Hooks/useServicosComposicoesInsumos";
import usePedidoState from "../../Hooks/useStateCriarPedido";
import useTipoDeSolicitacao from "../../Hooks/useTipoDeSolicitacao";
import { Anexo } from "../../Interface/IAnexos";
import { LoadingProps } from "../../Interface/ILoading";
import { DadosQuantitativos, EmpresaObra, InsumoComposicaoAtualizadoProps } from "../../Interface/ITabelaQuantitativo";
import { filtrarOpcoes } from "../../Utils/FiltrarOpcoes";

const VisualizarPedidos: React.FC<LoadingProps> = ({ setLoading }) => {
  const { numeroPedido } = useParams<{ numeroPedido: string }>();
  const numeroPedidoAsNumber = parseInt(numeroPedido ?? "0", 10);
  const detalhesDoPedido = useDetalhesDoPedido(numeroPedidoAsNumber);
  const historicoPedido = useHistoricoDoPedido(numeroPedidoAsNumber)
  const [aprovacoes, setAprovacoes] = useState<string[]>([]);
  const [pedidoAtual] = useState<number>(numeroPedidoAsNumber);
  const tipoDeSolicitacao = useTipoDeSolicitacao();
  const temPermissao = usePermissao();
  const [anexoAtualizado, setAnexoAtualizado] = useState<Anexo[]>([]);
  const [isFirstReprovado, setIsFirstReprovado] = useState(false);
  const {
    opcoesInsumoComposicao,
    setOpcoesInsumoComposicao,
    setItensSelecionadosQuantitativos,
    itensSelecionadosQuantitativos,
    setItensSelecionadosOrcamentos,
    itensSelecionadosOrcamentos,
    setItensSelecionados,
    setOrcamentosSelecionados,
    itensSelecionados,
    orcamentosSelecionados
  } = usePedidoState();
  const [empresaObra, setEmpresaObra] = useState<EmpresaObra>({
    codigoEmpresa: '',
    codigoObra: ''
  });

  const handleAtualizarItensOrcamento = (
    itensAtualizados: InsumoComposicaoAtualizadoProps[]
  ) => {
    setOrcamentosSelecionados(itensAtualizados);
  };

  const { composicoes, insumos } = useServicosComposicoesInsumos(opcoesInsumoComposicao);
  const handleSelectChangeQuantitativos = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;
    const selectedItem =
      opcoesInsumoComposicao === "Insumos"
        ? insumos.find((item: { descricao: string }) => item.descricao === selectedOption)
        : composicoes.find((item: { descricao: string }) => item.descricao === selectedOption);

    if (selectedItem) {
      const itemComMaterial = {
        ...selectedItem,
        material: opcoesInsumoComposicao === "Insumos",
        id: new Date().getTime(),
        descricao: `*${selectedItem.descricao}`
      };
      setItensSelecionadosQuantitativos((prevState) => [...prevState, itemComMaterial]);
      event.target.value = "";
    }
  };

  const location = useLocation();
  const { responsavelPelaEtapa } = location.state || {};
  useEffect(() => {
    setLoading(true);
    if (detalhesDoPedido && tipoDeSolicitacao && historicoPedido) {
      const aprovacoesExtraidas = historicoPedido.map((item: any) => item.aprovacao);
      setAprovacoes(aprovacoesExtraidas);
      const firstReprovado = aprovacoesExtraidas[0] === 'Reprovado';
      const desabled = firstReprovado && responsavelPelaEtapa === "Suprimentos 1"
      setIsFirstReprovado(desabled);
      setLoading(false);
    }
  }, [detalhesDoPedido, setLoading, tipoDeSolicitacao]);

  const [searchTermOrcamento, setSearchTermOrcamento] = useState("");

  const handleSearchChangeOrcamento = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTermOrcamento(e.target.value);
  };

  const filteredOrcamentoOptions = filtrarOpcoes(searchTermOrcamento, composicoes);

  const handleSelectChangeOrcamentos = (descricao: string) => {
    const selectedItem = composicoes.find(
      (item: { descricao: string }) => item.descricao === descricao
    );
    if (selectedItem) {
      const itemComAsterisco = { ...selectedItem, descricao: `*${selectedItem.descricao}` };
      setItensSelecionadosOrcamentos((prevState) => [...prevState, itemComAsterisco]);
    }
  };

  const handleOptionSelectOrcamento = (descricao: string) => {
    handleSelectChangeOrcamentos(descricao);
    setSearchTermOrcamento(""); // Limpa o campo de busca após a seleção
  };

  useEffect(() => {
    if (detalhesDoPedido && detalhesDoPedido.orcamento) {
      const itensAtualizados = detalhesDoPedido.orcamento.orcamentoItens.map(
        (item: any) => ({
          ...item,
          descricao: item.itemOrcamento,
          codigo: item.codigoOrcamento,
        })
      );
      setItensSelecionadosOrcamentos(itensAtualizados);
      setOrcamentosSelecionados(itensAtualizados);
    }
  }, [detalhesDoPedido]);

  useEffect(() => {
    if (detalhesDoPedido && detalhesDoPedido.pedido.quantitativos) {
      setEmpresaObra({
        codigoEmpresa: detalhesDoPedido.pedido.codigoEmpresa,
        codigoObra: detalhesDoPedido.pedido.codigoObra
      });
      const itensAtualizados = detalhesDoPedido.pedido.quantitativos.map(
        (item: any) => ({
          ...item,
          descricao: item.descricaoComposicao || item.descricao,
          codigo: item.codigoComposicao || item.codigo,
          categoriaUAU: item.categoriaUau || item.categoriaUAU,
          unidadeComposicao: item.unidade || item.unidadeComposicao,

        })
      );
      setItensSelecionadosQuantitativos(itensAtualizados);
      setItensSelecionados(itensAtualizados)
    }
  }, [detalhesDoPedido]);



  const removerItemOrcamentos = (index: number, itemAntigo: boolean) => {
    if (itemAntigo) {
      setItensSelecionadosOrcamentos((prevState) =>
        prevState.map((item, i) =>
          i === index
            ? {
              ...item,
              descricao: (item.descricao.match(/\*/g) || []).length === 1
                ? `*${item.descricao}`
                : `**${item.descricao}`
            }
            : item
        )
      );
      setOrcamentosSelecionados((prevState) =>
        prevState.map((item, i) =>
          i === index
            ? {
              ...item,
              descricao: (item.descricao.match(/\*/g) || []).length === 1
                ? `*${item.descricao}`
                : `**${item.descricao}`
            }
            : item
        )
      );
    } else {
      setItensSelecionadosOrcamentos((prevState) => prevState.filter((_, i) => i !== index));
    }
  };

  const removerItemQuantitativos = (index: number, itemAntigo: boolean) => {
    if (itemAntigo) {
      setItensSelecionadosQuantitativos((prevState) =>
        prevState.map((item, i) =>
          i === index
            ? {
              ...item,
              descricao: (item.descricao.match(/\*/g) || []).length === 1
                ? `*${item.descricao}`
                : `**${item.descricao}`
            }
            : item
        )
      );
      setItensSelecionados((prevState) =>
        prevState.map((item, i) =>
          i === index
            ? {
              ...item,
              descricao: (item.descricao.match(/\*/g) || []).length === 1
                ? `*${item.descricao}`
                : `**${item.descricao}`
            }
            : item
        )
      );
    } else {
      setItensSelecionadosQuantitativos((prevState) => prevState.filter((_, i) => i !== index));
    }
  };

  useEffect(() => {
    handleAtualizarItensOrcamento(itensSelecionadosOrcamentos);
    handleAtualizarItensQuantitativos(itensSelecionadosQuantitativos)
  }, [itensSelecionadosQuantitativos, itensSelecionadosOrcamentos])

  const handleAtualizarItensQuantitativos = useCallback(
    (dadosAtualizados: InsumoComposicaoAtualizadoProps[]) => {
      setItensSelecionados(dadosAtualizados);
    },
    [setItensSelecionados]
  );

  useEffect(() => {
    localStorage.setItem('dadosOrcamento', JSON.stringify(orcamentosSelecionados));
    localStorage.setItem('dadosQuantitativos', JSON.stringify(itensSelecionados));
  }, [orcamentosSelecionados, itensSelecionados]);

  if (detalhesDoPedido && tipoDeSolicitacao) {
    const { pedido, orcamento, anexos } = detalhesDoPedido;
    const {
      codigoEmpresa,
      razaoSocial,
      codigoObra,
      estado,
      dataPedido,
      tipoSolicitacao,
      descricaoServico,
      distrato,
      fornecedor,
      processoAnterior,
      contratoUAU,
      justificativaAditivo,
      dataAssinatura,
      dataFimExecucao,
      dataInicioExecucao,
      prazoContratacao,
      justificativaPedido,
      relacaoProjetos,
      especificacaoMateriais,
      nbrs,
      obrigacoesFornecedores,
      criterioMedicao,
      detalhamento,
      condicoesPagamentos,
      fornecedoresIndicados,
      nomeEmpreendimento,
      flagEmergencial,
      justificativaEmergencial,
      valorPagar
    } = pedido;

    const dadosFormulario = {
      codigoEmpresa,
      razaoSocial,
      codigoObra,
      estado,
      dataPedido,
      tipoSolicitacao,
      descricaoServico,
      distrato,
      fornecedor,
      contratoUAU,
      processoAnterior,
      justificativaAditivo,
      nomeEmpreendimento,
      valorPagar
    };
    const { quantitativos } = pedido;
    const dadosQuantitativos = (quantitativos || []).map(
      (item: DadosQuantitativos) => ({
        categoriaUau: item.categoriaUau,
        codigoComposicao: item.codigoComposicao,
        descricaoComposicao: item.descricaoComposicao,
        desvio: item.desvio,
        quantidadeSolicitada: item.quantidadeSolicitada,
        unidade: item.unidade,
        observacoes: item.observacoes,
        material: item.material,
        quantidadeUau: item.quantidadeUau,
        codigoPLM: item.codigoPLM,
        codigoEmpresa: pedido.codigoEmpresa,
        codigoObra: pedido.codigoObra
      })
    );

    const {
      valorTotalOrcamento,
      orcamentoItens,
      itemSemOrcamento,
      justificativa,
    } = orcamento;

    const dadosOrcamento = {
      orcamentoItens,
      valorTotalOrcamento,
    };

    const itensSemOrcamento = {
      itemSemOrcamento,
      justificativa,
    };

    const datas = {
      dataAssinatura,
      dataFimExecucao,
      dataInicioExecucao,
      prazoContratacao,
      flagEmergencial,
      justificativaEmergencial
    };

    const dadosDetalhesDoPedido = {
      justificativaPedido,
      relacaoProjetos,
      especificacaoMateriais,
      nbrs,
      obrigacoesFornecedores,
      criterioMedicao,
      detalhamento,
      condicoesPagamentos,
      fornecedoresIndicados,
    };


    return (
      <div className="d-flex flex-column gap-3" style={{ width: "95%" }}>
        <Navbar numeroPedido={pedidoAtual} anexoAtualizado={anexoAtualizado} />
        <Formulario
          mode="view"
          desabilitado={true}
          dadosFormulario={dadosFormulario}
          temPermissao={temPermissao}
          tipoDeSolicitacao={tipoDeSolicitacao}
          mostrarPrazoContratacao={tipoDeSolicitacao === "Contratação Inicial"}
        />
        <hr />
        {isFirstReprovado && (

          <OpcaoInsumoComposicao
            opcoesInsumoComposicao={opcoesInsumoComposicao}
            setOpcoesInsumoComposicao={setOpcoesInsumoComposicao}
            insumos={insumos}
            composicoes={composicoes}
            handleSelectChangeQuantitativos={handleSelectChangeQuantitativos}
          />
        )}
        <TabelaQuantitativos
          mode='view'
          dadosQuantitativos={itensSelecionadosQuantitativos}
          desabilitado={!isFirstReprovado}
          empresaObra={empresaObra}
          onRemoverItem={removerItemQuantitativos}
          onAtualizarItens={handleAtualizarItensQuantitativos}
        />
        {isFirstReprovado && (

          <BuscarCampo
            searchTerm={searchTermOrcamento}
            onSearchChange={handleSearchChangeOrcamento}
            onOptionSelect={handleOptionSelectOrcamento}
            filteredOptions={filteredOrcamentoOptions}
            placeholder="Código do item do orçamento..."
          />
        )}

        <TabelaOrcamentos
          mode="view"
          desabilitado={!isFirstReprovado}
          onRemoverItem={removerItemOrcamentos}
          onAtualizarItens={handleAtualizarItensOrcamento}
          itensSelecionados={itensSelecionadosOrcamentos}
        />
        <hr />
        <ItemSemOrcamento
          mode="view"
          desabilitado={true}
          itensSemOrcamento={itensSemOrcamento}
        />
        <hr />
        <DataAssinatura
          mode="view"
          desabilitado={true}
          datasDoPedido={datas}
          tipoSolicitacao={tipoSolicitacao}
        />
        <hr />
        <DetalhesPedido
          mode="view"
          desabilitado={true}
          dadosDetalhesDoPedido={dadosDetalhesDoPedido}
        />
        <hr />
        <Anexos mode="view" anexosIniciais={anexos} setAnexoAtualizado={setAnexoAtualizado} />
      </div>
    );
  }
  return null;
};

export default VisualizarPedidos;
