import React from "react";
import useDataAssinatura from "../../Hooks/useDataAssinatura";
import {
 CriarInputDataProps,
 InputDataProps,
 VisualizarInputDataProps,
} from "../../Interface/IInputData";
import AlertaBootstrap from "../Alertas";

const DataAssinatura: React.FC<InputDataProps & { tipoSolicitacao: string }> = (props) => {
 const { mode, tipoSolicitacao } = props;

 const createProps = mode === "create" ? (props as CriarInputDataProps) : null;
 const visualizarProps =
  mode === "view" ? (props as VisualizarInputDataProps) : null;

 const {
  showAlert,
  alertMessage,
  alertType,
  dataDeAssinatura,
  prazoParaContratacao,
  prazoParaExecucaoInicial,
  prazoParaExecucaoFinal,
  pedidoEmergencial,
  justificativaEmergencial,
  justificativaSelecionada,
  setDataDeAssinatura,
  setPrazoParaExecucaoInicial,
  setPrazoParaExecucaoFinal,
  setJustificativaSelecionada,
  validarDataDeAssinatura,
  validarPrazoInicial,
  validarPrazoFinal,
 } = useDataAssinatura(createProps);

 if (mode === "create" && createProps) {
  const { desabilitado } = createProps;

  return (
   <div className="container-fluid w-100">
    {showAlert && (
     <AlertaBootstrap mensagem={alertMessage} tipo={alertType} />
    )}
    <div className="row justify-content-between">
     <div className="col-sm-12 col-md-6 mt-5">
      {pedidoEmergencial && (
       <h6 className="text-danger">Pedido emergencial</h6>
      )}
      <div className="row gap-1">
       <div className="col-6">
        <div className="form-floating mb-3">
         <input
          type="date"
          className="form-control"
          id="DataDeAssinaturaDoContrato"
          disabled={desabilitado}
          value={dataDeAssinatura}
          onChange={(e) => {
           if (validarDataDeAssinatura(e.target.value)) {
            setDataDeAssinatura(e.target.value);
           }
          }}
         />
         <label
          className="lh-1 fs-6 text-primary text-wrap p-2"
          htmlFor="DataDeAssinaturaDoContrato"
         >
          Data necessária para a assinatura do contrato:
          <span className="text-danger">*</span>
         </label>
        </div>
       </div>
       {tipoSolicitacao === "Contratação Inicial" && (
        <div className="col-6">
         <div className="form-floating mb-3">
          <input
           type="date"
           className="form-control"
           id="PrazoParaContratacao"
           disabled
           value={prazoParaContratacao}
           onChange={(e) => {
            if (validarDataDeAssinatura(e.target.value)) {
             setDataDeAssinatura(e.target.value);
            }
           }}
          />
          <label
           className="lh-1 fs-6 text-primary text-wrap p-2"
           htmlFor="PrazoParaContratacao"
          >
           Prazo para contratação:
          </label>
         </div>
        </div>
       )}
      </div>
     </div>

     <div className="col-sm-12 col-md-6">
      <p className="mb-4 text-primary">
       Prazo para a execução dos serviços:
      </p>
      <div className="row gap-1">
       <div className="col-6">
        <div className="form-floating mb-3">
         <input
          type="date"
          className="form-control"
          id="PrazoParaExecucaoDoServicoInicial"
          disabled={desabilitado}
          value={prazoParaExecucaoInicial}
          min={dataDeAssinatura}
          onChange={(e) => {
           if (validarPrazoInicial(e.target.value)) {
            setPrazoParaExecucaoInicial(e.target.value);
           }
          }}
         />
         <label
          className="lh-1 fs-6 text-primary p-2"
          htmlFor="PrazoParaExecucaoDoServicoInicial"
         >
          Prazo para execução do serviço (início):
          <span className="text-danger">*</span>
         </label>
        </div>
       </div>
      </div>
      <div className="row gap-1">
       <div className="col-6">
        <div className="form-floating mb-3">
         <input
          type="date"
          className="form-control"
          id="PrazoParaExecucaoDoServicoFinal"
          disabled={desabilitado}
          value={prazoParaExecucaoFinal}
          min={prazoParaExecucaoInicial}
          onChange={(e) => {
           if (validarPrazoFinal(e.target.value)) {
            setPrazoParaExecucaoFinal(e.target.value);
           }
          }}
         />
         <label
          className="lh-1 fs-6 text-primary p-2"
          htmlFor="PrazoParaExecucaoDoServicoFinal"
         >
          Prazo para execução do serviço (término):
          <span className="text-danger">*</span>
         </label>
        </div>
       </div>
      </div>
     </div>
     {pedidoEmergencial && (
      <div className="form-floating w-100">
       <select
        className="form-select form-control"
        id="Justificativa"
        aria-label="Floating label select example"
        value={justificativaSelecionada}
        onChange={(e) => setJustificativaSelecionada(e.target.value)}
       >
        <option value="">Selecione...</option>
        {justificativaEmergencial
         .slice()
         .sort((a: any, b: any) =>
          a.descricao.localeCompare(b.descricao)
         )
         .map((justificativa: any) => (
          <option
           key={justificativa.id}
           value={justificativa.descricao}
          >
           {justificativa.descricao}
          </option>
         ))}
       </select>
       <label className="text-primary" htmlFor="Justificativa">
        Justificativa<span className="text-danger">*</span>
       </label>
      </div>
     )}
    </div>
   </div>
  );
 } else if (mode === "view" && visualizarProps) {
  const { datasDoPedido } = visualizarProps;
  const ConverterDataParaFormatoInput = (data: string): string => {
   const [dia, mes, ano] = data.split(" ")[0].split("/");
   return `${ano}-${mes}-${dia}`;
  };
  const dataFormatada = datasDoPedido.prazoContratacao
   ? ConverterDataParaFormatoInput(datasDoPedido.prazoContratacao)
   : "";

  return (
   <div className="container-fluid w-100">
    <div className="row justify-content-between">
     {datasDoPedido.flagEmergencial && (
      <h6 className="text-danger">Pedido emergencial</h6>
     )}
     <div className="col-sm-12 col-md-6 mt-5">
      <div className="row gap-1">
       <div className="col-6">
        <div className="form-floating mb-3">
         <input
          type="date"
          className="form-control"
          id="DataDeAssinaturaDoContrato"
          disabled
          value={datasDoPedido.dataAssinatura}
         />
         <label
          className="lh-1 fs-6 text-primary text-wrap p-2"
          htmlFor="DataDeAssinaturaDoContrato"
         >
          Data necessária para a assinatura do contrato:
         </label>
        </div>
       </div>
       {tipoSolicitacao === "Contratação Inicial" && (
        <div className="col-6">
         <div className="form-floating mb-3">
          <input
           type="date"
           className="form-control"
           id="PrazoParaContratacao"
           disabled
           value={dataFormatada}
          />
          <label
           className="lh-1 fs-6 text-primary text-wrap p-2"
           htmlFor="PrazoParaContratacao"
          >
           Prazo para contratação:
          </label>
         </div>
        </div>
       )}
      </div>
     </div>

     <div className="col-sm-12 col-md-6">
      <p className="mb-4 text-primary">
       Prazo para a execução dos serviços:
      </p>
      <div className="row gap-1">
       <div className="col-6">
        <div className="form-floating mb-3">
         <input
          type="date"
          className="form-control"
          id="PrazoParaExecucaoDoServicoInicial"
          disabled
          value={datasDoPedido.dataInicioExecucao}
         />
         <label
          className="lh-1 fs-6 text-primary p-2"
          htmlFor="PrazoParaExecucaoDoServicoInicial"
         >
          Prazo para execução do serviço (início):
         </label>
        </div>
       </div>
      </div>
      <div className="row gap-1">
       <div className="col-6">
        <div className="form-floating mb-3">
         <input
          type="date"
          className="form-control"
          id="PrazoParaExecucaoDoServicoFinal"
          disabled
          value={datasDoPedido.dataFimExecucao}
         />
         <label
          className="lh-1 fs-6 text-primary p-2"
          htmlFor="PrazoParaExecucaoDoServicoFinal"
         >
          Prazo para execução do serviço (término):
         </label>
        </div>
       </div>
      </div>
     </div>
     {datasDoPedido.flagEmergencial && (
      <div className="form-floating w-100">
       <textarea
        className="form-control"
        id="Justificativa"
        aria-label="Floating label select example"
        disabled
        value={datasDoPedido.justificativaEmergencial}
       />
       <label className="text-primary" htmlFor="Justificativa">
        Justificativa<span className="text-danger">*</span>
       </label>
      </div>
     )}
    </div>
   </div>
  );
 }

 return null;
};

export default DataAssinatura;
