import { useState, useEffect, useCallback } from "react";
import {
  getServicos,
  getComposicoes,
  getInsumos,
} from "../Repository/domain/Servicos";
import useAlert from "./useAlert";

const useServicosComposicoesInsumos = (opcoesInsumoComposicao?: string) => {
  const [servicos, setServicos] = useState<any>([]);
  const [composicoes, setComposicoes] = useState<any>([]);
  const [insumos, setInsumos] = useState<any>([]);
  const { showAlertMessage } = useAlert();

  const fetchServicos = useCallback(async () => {
    try {
      const listarServicos = await getServicos();
      setServicos(listarServicos);
    } catch (error) {
      showAlertMessage(" " + error, "danger");
    }
  }, [showAlertMessage]);

  const fetchComposicoes = useCallback(async () => {
    try {
      const itensComposicoes = await getComposicoes();
      setComposicoes(itensComposicoes);
    } catch (error) {
      showAlertMessage(" " + error, "danger");
    }
  }, [showAlertMessage]);

  const fetchInsumos = useCallback(async () => {
    try {
      const itensInsumos = await getInsumos();
      setInsumos(itensInsumos);
    } catch (error) {
      showAlertMessage(" " + error, "danger");
    }
  }, [showAlertMessage]);

  useEffect(() => {
    fetchServicos();
  }, [fetchServicos]);

  useEffect(() => {
    if (opcoesInsumoComposicao === "Composicoes") {
      fetchComposicoes();
    } else if (opcoesInsumoComposicao === "Insumos") {
      fetchInsumos();
    }
  }, [opcoesInsumoComposicao, fetchComposicoes, fetchInsumos]);

  return { servicos, composicoes, insumos };
};

export default useServicosComposicoesInsumos;
