import React, { useEffect, useState } from "react";
import { usePermissao } from "../../Hooks/usePermissao";
import { Fornecedor } from "../../Interface/IFornecedorUAU";
import {
 AtualizacaoFornecedorProps,
 ResumoContratacaoProps,
} from "../../Interface/IResumoContratacao";
import {
 getObterFornecedoresNoUAU,
 putAtualizarFornecedor,
} from "../../Repository/domain/Pedidos";
import { formatarMoedaBrasileira } from "../../Utils/FormatarMoedaBrasileira";

const formatarCNPJ = (value: string): string => {
 const cleanedValue = value.replace(/\D/g, "");
 if (cleanedValue.length === 14) {
  return cleanedValue.replace(
   /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
   "$1.$2.$3/$4-$5"
  );
 }
 return value;
};

const ResumoContratacao: React.FC<ResumoContratacaoProps> = ({
 resumoContratacao,
 numeroPedido,
}) => {
 const [dadosDoFornecedor, setDadosDoFornecedor] = useState<Fornecedor[]>([]);
 const [fornecedor, setFornecedor] = useState<string | undefined>(resumoContratacao!.fornecedorVencedor);
 const [fornecedoresEncontrados, setFornecedoresEncontrados] = useState<boolean>(false);
 const temPermissao = usePermissao();
 const [loadingSalvar, setLoadingSalvar] = useState<boolean>(false);
 const [loadingBuscar, setLoadingBuscar] = useState<boolean>(false);
 const [formValues, setFormValues] = useState({
  valorContratacao: resumoContratacao.valorContratacao || "R$ 0,00",
  valorOrcamento: resumoContratacao.valorOrcamento || "R$ 0,00",
  fornecedorVencedor: resumoContratacao.fornecedorVencedor || "",
  cnpjFornecedor: resumoContratacao.cnpjFornecedor || "",
  fornecedor: "",
 });

 const [showPopup, setShowPopup] = useState<boolean>(false);

 useEffect(() => {
  setFormValues({
   valorContratacao: resumoContratacao.valorContratacao || "R$ 0,00",
   valorOrcamento: resumoContratacao.valorOrcamento || "R$ 0,00",
   fornecedorVencedor: resumoContratacao.fornecedorVencedor || "",
   cnpjFornecedor: resumoContratacao.cnpjFornecedor || "",
   fornecedor: !temPermissao
    ? `${formatarCNPJ(resumoContratacao.cnpjFornecedor)} - ${resumoContratacao.fornecedorVencedor
    }`
    : "",
  });
  setFornecedor(resumoContratacao.fornecedorVencedor || "");
 }, [resumoContratacao]);

 const handleInputChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
 ) => {
  const { id, value } = e.target;
  setFormValues({ ...formValues, [id]: value });
 };

 const handleFornecedorInputChange = (
  e: React.ChangeEvent<HTMLInputElement>
 ) => {
  setFornecedor(e.target.value);
  setFormValues({
   ...formValues,
   fornecedor: e.target.value,
   fornecedorVencedor: e.target.value,
  });
 };

 const buscarFornecedor = async () => {
  try {
   setLoadingBuscar(true);
   if (fornecedor !== undefined && fornecedor !== "") {
    const res = await getObterFornecedoresNoUAU(fornecedor);

    const dadosFormatados = res
     .filter(
      (item: Fornecedor) =>
       item.fornecedor.toLowerCase().includes(fornecedor.toLowerCase()) ||
       item.documento.includes(fornecedor)
     )
     .map((item) => ({
      ...item,
      label: `${formatarCNPJ(item.documento)} - ${item.fornecedor}`,
     }));
    setDadosDoFornecedor(dadosFormatados);
    setFornecedoresEncontrados(true);
   }
  } catch (error) {
   console.error("Erro ao buscar fornecedor:", error);
  } finally {
   setLoadingBuscar(false);
  }
 };

 const handleFornecedorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  const selectedFornecedor = e.target.value;

  const fornecedorSelecionado = dadosDoFornecedor.find(
   (item) => item.codigoFornecedor === Number(selectedFornecedor)
  );

  if (fornecedorSelecionado) {
   setFormValues({
    ...formValues,
    fornecedorVencedor: fornecedorSelecionado.fornecedor,
    cnpjFornecedor: fornecedorSelecionado.documento,
    fornecedor: `${formatarCNPJ(fornecedorSelecionado.documento)} - ${fornecedorSelecionado.fornecedor}`,
   });
  }
 };

 const removerFornecedor = () => {
  setFormValues({
   ...formValues,
   fornecedorVencedor: "",
   cnpjFornecedor: "",
   fornecedor: "",
  });
  setFornecedor("");
  setFornecedoresEncontrados(false);
 };

 const salvarAtualizacoesDoFornecedor = async () => {
  try {
   setLoadingSalvar(true);
   const dadosFornecedorAtualizado: AtualizacaoFornecedorProps = {
    codigoPedido: numeroPedido ?? 0,
    descricaoTipoSolicitacao: "",
    fornecedores: {
     fornecedorVencedor: formValues.fornecedorVencedor,
     cnpjFornecedor: formValues.cnpjFornecedor,
     valorContratacao: formValues.valorContratacao,
     valorOrcamento: formValues.valorOrcamento,
    },
   };
   await putAtualizarFornecedor(dadosFornecedorAtualizado);
   setShowPopup(true);
  } catch (error: any) {
   alert("Erro ao criar pedido: " + error.message);
  } finally {
   setLoadingSalvar(false);
  }
 };

 const closePopup = () => {
  setShowPopup(false);
 };

 const {
  codigoPedido,
  codigoObra,
  dataAssinatura,
  descricaoServico,
  detalhamento,
 } = resumoContratacao;

 const isFornecedorVencedorFilled = fornecedor !== "";

 return (
  <>
   <h5>Dados da contratação</h5>
   <form className="d-flex w-100 flex-column gap-3">
    <div className="d-flex gap-3">
     <div className="form-floating w-50">
      <input className="form-control" id="Solicitacao" value={codigoPedido} disabled />
      <label className="text-primary" htmlFor="Solicitacao">
       Solicitação:
      </label>
     </div>
     <div className="form-floating w-50">
      <input className="form-control" id="Obra" value={codigoObra} disabled />
      <label className="text-primary" htmlFor="Obra">
       Obra:
      </label>
     </div>
     <div className="form-floating w-50">
      <input
       className="form-control"
       id="valorContratacao"
       disabled={!temPermissao}
       value={formatarMoedaBrasileira(formValues.valorContratacao)}
       onChange={handleInputChange}
      />
      <label className="text-primary" htmlFor="ValorContratacao">
       Valor da contratação: <span className="text-danger">*</span>
      </label>
     </div>
     <div className="form-floating w-50">
      <input
       className="form-control"
       id="valorOrcamento"
       disabled={!temPermissao}
       value={formatarMoedaBrasileira(formValues.valorOrcamento)}
       onChange={handleInputChange}
      />
      <label className="text-primary" htmlFor="ValorOrcamento">
       Valor do orçamento: <span className="text-danger">*</span>
      </label>
     </div>
    </div>

    <div className="d-flex gap-3 mt-3">
     <div className="form-floating w-50">
      <input
       type="date"
       className="form-control"
       id="DataAssinaturaContrato"
       value={dataAssinatura}
       disabled
      />
      <label className="text-primary" htmlFor="DataAssinaturaContrato">
       Data de assinatura do contrato:
      </label>
     </div>
     <div className="form-floating w-50">
      <input
       className="form-control"
       id="TipoDeServicoContratado"
       value={descricaoServico}
       disabled
      />
      <label className="text-primary" htmlFor="TipoDeServicoContratado">
       Tipo de serviço contratado:
      </label>
     </div>
    </div>

    <div className="d-flex align-items-center gap-2 mt-3">
     <div className="form-floating flex-grow-1">
      {fornecedoresEncontrados ? (
       <select
        className="form-control"
        id="FornecedorVencedor"
        disabled={!temPermissao}
        onChange={handleFornecedorChange}
       >
        <option value="">Selecione...</option>
        {dadosDoFornecedor.map((fornecedor) => (
         <option key={fornecedor.codigoFornecedor} value={fornecedor.codigoFornecedor}>
          {fornecedor.label}
         </option>
        ))}
       </select>
      ) : (
       <input
        className="form-control"
        id="FornecedorVencedor"
        disabled={!temPermissao}
        value={formValues.fornecedor}
        onChange={handleFornecedorInputChange}
       />
      )}
      <label className="text-primary" htmlFor="FornecedorVencedor">
       Fornecedor vencedor (qualificado): <span className="text-danger">*</span>
      </label>
     </div>

     <div className="d-flex gap-2">
      <button
       type="button"
       className={`btn ${loadingBuscar ? "btn-outline-secondary" : "btn-outline-primary"}`}
       style={{ padding: "8px 12px", fontSize: "0.900rem", width: "auto" }}
       disabled={!temPermissao || loadingBuscar}
       onClick={buscarFornecedor}
      >
       {loadingBuscar ? "Buscando..." : "Buscar Fornecedor"}
      </button>
      <button
       type="button"
       className={`btn ${loadingSalvar ? "btn-outline-secondary" : "btn-outline-success"}`}
       style={{ padding: "8px 12px", fontSize: "0.900rem", width: "auto" }}
       disabled={!temPermissao || loadingSalvar || !isFornecedorVencedorFilled}
       onClick={salvarAtualizacoesDoFornecedor}
      >
       {loadingSalvar ? "Salvando..." : "Salvar Fornecedor"}
      </button>
      <button
       type="button"
       className="btn btn-outline-danger"
       style={{ padding: "8px 12px", fontSize: "0.900rem", width: "auto" }}
       disabled={!temPermissao || !isFornecedorVencedorFilled}
       onClick={removerFornecedor}
      >
       Remover Fornecedor
      </button>
     </div>
    </div>

    <div className="form-floating mt-3">
     <textarea
      className="form-control w-100 h-100"
      id="DetalhamentoServico"
      value={detalhamento}
      disabled
     ></textarea>
     <label className="lh-1 fs-6 text-primary text-wrap" htmlFor="DetalhamentoServico">
      Detalhamento do serviço
     </label>
    </div>
   </form>

   {/* Pop-up de confirmação */}
   {showPopup && (
    <div
     className="modal d-block"
     tabIndex={-1}
     role="dialog"
     style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
     <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
       <div className="modal-header">
        <h5 className="modal-title">Confirmação</h5>
        <button type="button" className="btn-close" aria-label="Close" onClick={closePopup}></button>
       </div>
       <div className="modal-body">
        <p>Fornecedor salvo com sucesso!</p>
       </div>
       <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={closePopup}>
         Fechar
        </button>
       </div>
      </div>
     </div>
    </div>
   )}
  </>
 );
};

export default ResumoContratacao;
