import React, { useState } from "react";
import { Pagination, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TabelaPaginadaProps } from "../../Interface/ITabelaPaginada";

const TabelaPaginada: React.FC<TabelaPaginadaProps> = ({
 dados,
 itensPorPagina,
}) => {
 const [paginaAtual, setPaginaAtual] = useState(1);
 const [, setNumeroPedidoSelecionado] = useState<number | null>(null);

 const indiceUltimoItem = paginaAtual * itensPorPagina;
 const indicePrimeiroItem = indiceUltimoItem - itensPorPagina;
 const dadosPaginaAtual = dados.slice(indicePrimeiroItem, indiceUltimoItem);
 const totalPaginas = Math.ceil(dados.length / itensPorPagina);

 const handleClick = (numeroPagina: number) => {
  setPaginaAtual(numeroPagina);
 };

 const handleVisualizarPedido = (numeroPedido: number) => {
  setNumeroPedidoSelecionado(numeroPedido);
 };
 return (
  <div>
   <Table striped bordered hover className="text-center" style={{ fontSize: "13px" }}>
    <thead>
     <tr>
      <th className="align-middle">Solicitação</th>
      <th className="align-middle">Estado</th>
      <th className="align-middle">Empresa</th>
      <th className="align-middle">Obra</th>
      <th className="align-middle">Tipo solicitação</th>
      <th className="align-middle">Tipo serviço</th>
      <th className="align-middle">Data solicitação</th>
      <th className="align-middle">Emergencial</th>
      <th className="align-middle">Data ass. contr.</th>
      <th className="align-middle">Prazo p/ ass. contr.</th>
      <th className="align-middle">Etapa</th>
      <th className="align-middle">Responsável</th>
      <th className="align-middle">Ação</th>
     </tr>
    </thead>
    <tbody>
     {dadosPaginaAtual.map((dado) => (
      <tr key={dado.numeroPedido}>
       <td className="align-middle">{dado.numeroPedido}</td>
       <td className="align-middle">{dado.estado}</td>
       <td className="align-middle">{dado.empresa}</td>
       <td className="align-middle">{dado.codigoObra}</td>
       <td className="align-middle">{dado.tipoSolicitacao}</td>
       <td className="align-middle">{dado.tipoServico}</td>
       <td className="align-middle">{dado.dataPedido}</td>
       <td className="align-middle">
        {dado.emergencial ? "Sim" : "Não"}
       </td>
       <td className="align-middle">{dado.dataAssinaturaContrato}</td>
       <td className="align-middle">{dado.prazoAssinaturaContrato}</td>
       <td className="align-middle">{dado.etapa}</td>
       <td className="align-middle">{dado.responsavelEtapa}</td>
       <td className="align-middle">
        {dado.acao ? (
         <Link
          to={`/visualizarPedido/${dado.numeroPedido}`}
          className="btn btn-outline-primary"
          onClick={() => handleVisualizarPedido(dado.numeroPedido)}
          state={{
           etapa: dado.etapaStatus,
           responsavelPelaEtapa: dado.responsavelEtapa,
           acao: dado.acao,
          }}
         >
          Executar
         </Link>
        ) : (
         <Link
          to={`/visualizarPedido/${dado.numeroPedido}`}
          className="btn btn-outline-primary"
          onClick={() => handleVisualizarPedido(dado.numeroPedido)}
          state={{
           etapa: dado.etapa,
           responsavelPelaEtapa: dado.responsavelEtapa,
           acao: dado.acao,
          }}
         >
          Visualizar
         </Link>
        )}
       </td>
      </tr>
     ))}
    </tbody>
   </Table>
   <Pagination>
    <Pagination.First
     onClick={() => handleClick(1)}
     disabled={paginaAtual === 1}
    />
    <Pagination.Prev
     onClick={() => handleClick(paginaAtual - 1)}
     disabled={paginaAtual === 1}
    />
    {[...Array(totalPaginas)].map((_, index) => (
     <Pagination.Item
      key={index + 1}
      active={index + 1 === paginaAtual}
      onClick={() => handleClick(index + 1)}
     >
      {index + 1}
     </Pagination.Item>
    ))}
    <Pagination.Next
     onClick={() => handleClick(paginaAtual + 1)}
     disabled={paginaAtual === totalPaginas}
    />
    <Pagination.Last
     onClick={() => handleClick(totalPaginas)}
     disabled={paginaAtual === totalPaginas}
    />
   </Pagination>
  </div>
 );
};

export default TabelaPaginada;
