import React, { useEffect, useMemo, useRef, useState } from "react";
import { BsDownload } from "react-icons/bs";
import { IoMdRemove } from "react-icons/io";
import { Anexo, AnexosProps, tipoDeAnexo } from "../../Interface/IAnexos";
import { getListaTipoAnexo } from "../../Repository/domain/Pedidos";

const Anexos: React.FC<AnexosProps> = ({
 mode,
 anexosIniciais = [],
 onAnexosChange,
 setAnexoAtualizado
}) => {
 const [listaDeAnexos, setListaDeAnexos] = useState<tipoDeAnexo[]>([]);
 const [tipoDocumentoSelecionado, setTipoDocumentoSelecionado] =
  useState<string>("");
 const [, setNomeArquivo] = useState<string>("");
 const [anexos, setAnexos] = useState<Anexo[]>([]);

 const initialAnexos = useRef(false);

 const memoizedAnexosIniciais = useMemo(
  () => anexosIniciais,
  [anexosIniciais]
 );

 useEffect(() => {
  const fetchAnexos = async () => {
   try {
    const anexos: any =
     (await getListaTipoAnexo()) as unknown as tipoDeAnexo;
    setListaDeAnexos(anexos);
   } catch (error) {
    alert("Erro ao listar opções de anexo:" + error);
   }
  };
  fetchAnexos();
 }, []);

 useEffect(() => {
  if (mode === "view" && !initialAnexos.current) {
   const formattedAnexos = memoizedAnexosIniciais.map((anexo, index) => ({
    id: index,
    tipoDocumento: anexo.tipoArquivo,
    nomeArquivo: anexo.nomeArquivo,
    url: anexo.url
   }));
   setAnexos(formattedAnexos);
   initialAnexos.current = true;
  }
 }, [mode, memoizedAnexosIniciais]);

 const handleTipoDocumentoChange = (
  event: React.ChangeEvent<HTMLSelectElement>
 ) => {
  setTipoDocumentoSelecionado(event.target.value);
 };

 const handleArquivoSelecionado = (
  event: React.ChangeEvent<HTMLInputElement>
 ) => {
  if (event.target.files && event.target.files[0]) {
   const file = event.target.files[0];
   setNomeArquivo(file.name);
   const novoAnexo = {
    id: new Date().getTime(),
    tipoDocumento: tipoDocumentoSelecionado,
    nomeArquivo: file.name,
    arquivo: file,
   };
   const novosAnexos = [...anexos, novoAnexo];
   setAnexos(novosAnexos);
   setTipoDocumentoSelecionado("");
   setNomeArquivo("");

   if (mode === "create") {
    onAnexosChange?.(novosAnexos);
   }
  }
 };

 const removerAnexo = (id: number) => {
  const novosAnexos = anexos.filter((anexo) => anexo.id !== id);
  setAnexos(novosAnexos);

  // Chame o callback apenas se o modo for "create"
  if (mode === "create") {
   onAnexosChange?.(novosAnexos);
  }
 };

 useEffect(() => {
  if (anexos && setAnexoAtualizado) {
   setAnexoAtualizado(anexos);
  }
 }, [anexos]);

 return (
  <div>
   {/* {mode === "create" && ( */}
   <div className="d-flex gap-2">
    <div className="form-floating w-50">
     <select
      className="form-select"
      id="TipoDocumento"
      aria-label="Floating label select example"
      value={tipoDocumentoSelecionado}
      onChange={handleTipoDocumentoChange}
     >
      <option value="">Selecione...</option>
      {listaDeAnexos
       .slice()
       .sort((a, b) => a.tipoAnexo.localeCompare(b.tipoAnexo))
       .map((anexo) => (
        <option key={anexo.id} value={anexo.tipoAnexo}>
         {anexo.tipoAnexo}
        </option>
       ))}
     </select>
     <label className="text-primary" htmlFor="TipoDocumento">
      Tipo de Documento
     </label>
    </div>
    <div className="form-floating mb-3 w-50">
     <input
      type="file"
      className="form-control"
      id="CampoArquivos"
      placeholder="Insira um arquivo"
      onChange={handleArquivoSelecionado}
     />
     <label htmlFor="CampoArquivos">Nome do arquivo</label>
    </div>
   </div>
   {/* )} */}
   <table className="table table-striped text-center table-hover table-bordered border-lightinfo">
    <thead>
     <tr className="table-primary text-center">
      <th scope="col">Tipo de documento</th>
      <th scope="col">Nome do arquivo</th>
      <th scope="col">Ação</th>
     </tr>
    </thead>
    <tbody>
     {anexos.map((anexo) => (
      <tr key={anexo.id}>
       <td>{anexo.tipoDocumento}</td>
       <td>{anexo.nomeArquivo}</td>
       <td>
        <div className="d-flex gap-3 justify-content-center">
         {mode === "view" && (
          <a href={anexo.url} download>
           <button type="button" className="btn btn-outline-primary">
            <BsDownload />
           </button>
          </a>
         )}
         {/* {mode === "create" && ( */}
         <button
          type="button"
          className="btn btn-outline-danger"
          onClick={() => removerAnexo(anexo.id)}
         >
          <IoMdRemove />
         </button>
         {/* )} */}
        </div>
       </td>
      </tr>
     ))}
    </tbody>
   </table>
  </div>
 );
};

export default Anexos;
