import React, { useState } from "react";
import { OpcaoInsumoComposicaoProps } from "../../Interface/IOpcaoInsumoComposicao";
import { filtrarOpcoes } from "../../Utils/FiltrarOpcoes";
import BuscarCampo from "../BuscarCampos";

const OpcaoInsumoComposicao: React.FC<OpcaoInsumoComposicaoProps> = ({
 opcoesInsumoComposicao,
 setOpcoesInsumoComposicao,
 insumos,
 composicoes,
 handleSelectChangeQuantitativos,
}) => {
 const [searchTerm, setSearchTerm] = useState("");

 const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  setSearchTerm(e.target.value);
 };

 const itemsToFilter =
  opcoesInsumoComposicao === "Insumos" ? insumos : composicoes;

 const filteredOptions = filtrarOpcoes(searchTerm, itemsToFilter);

 const handleOptionClick = (descricao: string) => {
  setSearchTerm(descricao);
  handleSelectChangeQuantitativos({
   target: { value: descricao },
  } as React.ChangeEvent<HTMLSelectElement>);
 };

 return (
  <div className="d-flex flex-column gap-4">
   <div className="d-flex gap-2">
    <input
     type="radio"
     className="btn-check"
     name="itensCotacao"
     id="Insumos"
     checked={opcoesInsumoComposicao === "Insumos"}
     onChange={() => setOpcoesInsumoComposicao("Insumos")}
    />
    <label className="btn btn-outline-primary bg-none" htmlFor="Insumos">
     Insumos
    </label>

    <input
     type="radio"
     className="btn-check"
     name="itensCotacao"
     id="Composicoes"
     checked={opcoesInsumoComposicao === "Composicoes"}
     onChange={() => setOpcoesInsumoComposicao("Composicoes")}
    />
    <label className="btn btn-outline-primary" htmlFor="Composicoes">
     Composições
    </label>
   </div>

   <div className="form-floating">
    <BuscarCampo
     searchTerm={searchTerm}
     onSearchChange={handleSearchChange}
     onOptionSelect={handleOptionClick}
     filteredOptions={filteredOptions}
     placeholder="Itens da solicitação/cotação"
    />
   </div>
  </div >
 );
};

export default OpcaoInsumoComposicao;
