import { NavLink, useLocation } from "react-router-dom";
import { Anexo } from "../../Interface/IAnexos";
import { putAtualizarAnexoPedido } from "../../Repository/domain/Pedidos";
interface NavbarProps {
    numeroPedido: number;
    anexoAtualizado: Anexo[]
}

const Navbar: React.FC<NavbarProps> = ({ numeroPedido, anexoAtualizado }) => {
    const location = useLocation();
    const { etapa, responsavelPelaEtapa, acao } = location.state || {};


    const handleAprovarPedido = async () => {
        try {
            await putAtualizarAnexoPedido(numeroPedido, anexoAtualizado);
        } catch (error) {
            console.error('Erro ao salvar itens.', error)
        }
    }
    return (
        <main className="row gap-4">
            <nav className="navbar navbar-expand-lg navbar-light bg-light d-flex">
                <div className="container d-flex">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0 d-flex">
                        <li className="nav-item">
                            <NavLink
                                className="nav-link"
                                to={`/visualizarPedido/${numeroPedido}`}
                                state={{
                                    etapa,
                                    responsavelPelaEtapa,
                                    acao
                                }}
                            >
                                Visualizar Pedido
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                onClick={handleAprovarPedido}
                                className="nav-link"
                                to={`/aprovacoes/${numeroPedido}`}
                                state={{
                                    etapa,
                                    responsavelPelaEtapa,
                                    acao
                                }}
                            >
                                Aprovar Pedido
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </main>
    );
};

export default Navbar;