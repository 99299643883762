import React, { useState, useEffect } from "react";
import { AlertaProps } from "../../Interface/IAlerta";

const AlertaBootstrap: React.FC<AlertaProps> = ({
  mensagem,
  tipo = "info",
  duracao = 5000,
  onFechar,
}) => {
  const [visivel, setVisivel] = useState(true);
  const [hover, setHover] = useState(false);
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    if (!hover) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress - 100 / (duracao / 100);
          if (newProgress <= 0) {
            clearInterval(interval);
            setVisivel(false);
            if (onFechar) {
              onFechar();
            }
            return 0;
          }
          return newProgress;
        });
      }, 100);

      return () => clearInterval(interval);
    }
  }, [hover, duracao, onFechar]);

  const tipoClasses: { [key: string]: string } = {
    success: "alert-success",
    info: "alert-info",
    warning: "alert-warning",
    danger: "alert-danger",
  };

  const alertaTipo = tipoClasses[tipo] || tipoClasses.info;

  if (!visivel) return null;

  return (
    <div
      className={`alert ${alertaTipo} d-flex flex-column align-items-center animate__animated animate__bounceInDown`}
      role="alert"
      style={{
        position: "fixed",
        top: "15%",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 1000,
        width: "fit-content",
        maxWidth: "300px",
        padding: "16px 24px 16px 16px", // Adicionado padding extra à direita para o botão
        cursor: "pointer",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        textAlign: "center", // Centralizar texto
        fontSize: "14px", // Tamanho da fonte
      }}
      onClick={() => {
        setVisivel(false);
        if (onFechar) {
          onFechar();
        }
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <button
        type="button"
        className="btn-close"
        aria-label="Close"
        onClick={(e) => {
          e.stopPropagation();
          setVisivel(false);
          if (onFechar) {
            onFechar();
          }
        }}
        style={{
          position: "absolute",
          top: "5px",
          right: "10px",
          cursor: "pointer",
        }}
      ></button>
      <div>{mensagem}</div>
      <div
        style={{ width: "100%", position: "absolute", bottom: "0", left: "0" }}
      >
        <div
          style={{
            height: "5px",
            background: "rgba(0, 0, 0, 0.1)",
            borderRadius: "0 0 8px 8px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: `${progress}%`,
              height: "100%",
              background: "rgba(0, 0, 0, 0.3)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AlertaBootstrap;
