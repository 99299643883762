import React, { useEffect, useState } from "react";
import { IoMdRemove } from "react-icons/io";
import {
    CriarTabelaOrcamentosProps,
    TabelaOrcamentosProps,
    VisualizarTabelaOrcamentosProps
} from "../../Interface/ITabelaOrcamento";
import { formatarMoedaBrasileira, mascaraMoeda } from "../../Utils/FormatarMoedaBrasileira";
import "./style.scss";

const TabelaOrcamentos: React.FC<TabelaOrcamentosProps> = (props) => {
    const { mode, desabilitado, itensSelecionados } = props;
    const createProps = mode === "create" ? (props as CriarTabelaOrcamentosProps) : null;
    const viewProps = mode === "view" ? (props as VisualizarTabelaOrcamentosProps) : null;
    const [somaTotalAjustado, setSomaTotalAjustado] = useState<number>(0);

    useEffect(() => {
        if (mode === "view") {
            const itensAtivos = itensSelecionados.filter(item => (item.descricao.match(/\*/g) || []).length != 2);
            const total = itensAtivos.reduce((acc, item) => {
                const valorAjustado = parseFloat(item.valorAjustado ?? "0");
                return acc + valorAjustado;
            }, 0);
            setSomaTotalAjustado(total);
        }
    }, [itensSelecionados.map(item => item.valorAjustado)]);
    const calcularValorOrcamento = (quantidadeOrcada: number, valorUnitario: string | number) => {
        const valorUnitarioNumerico = typeof valorUnitario === "string"
            ? parseFloat(valorUnitario.replace(/[R$.,]/g, '')) / 100
            : valorUnitario;

        return quantidadeOrcada * valorUnitarioNumerico;
    };

    if (mode === "create" && createProps) {
        const { desabilitado, itensSelecionados, onRemoverItem, onAtualizarItens } =
            createProps;
        return (
            <>
                <table className="table table-striped text-center table-hover table-bordered border-lightinfo">
                    <thead>
                        <tr className="table-primary text-center">
                            <th scope="col">Código do orçamento</th>
                            <th scope="col">Item orçamento</th>
                            <th scope="col">Unidade orçada</th>
                            <th scope="col">Quantidade orçada</th>
                            <th scope="col">Vlr unitário do orçamento (em reais)</th>
                            <th scope="col">Valor orçamento</th>
                            <th scope="col">Valor ajustado</th>
                            <th scope="col">Observações</th>
                            <th scope="col">Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {itensSelecionados.map((item, id) => {
                            const quantidadeOrcada =
                                item.quantidadeOrcada !== undefined ? item.quantidadeOrcada : 0;
                            const valorOrcamento = calcularValorOrcamento(quantidadeOrcada, item.valorUnitario || "0");
                            itensSelecionados[id].valorAjustado = valorOrcamento.toFixed(2).toString();
                            itensSelecionados[id].valorOrcamento = valorOrcamento.toFixed(2).toString();
                            return (
                                <tr key={id}>
                                    <td>{item.codigo}</td>
                                    <td>{item.descricao}</td>
                                    <td>
                                        <input
                                            type="text"
                                            id={`unidade-orcada-${id}`}
                                            className="form-control"
                                            disabled={desabilitado}
                                            value={item.unidadeOrcada || ""}
                                            onChange={(e) => {
                                                const novosItens = [...itensSelecionados];
                                                novosItens[id].unidadeOrcada = e.target.value;
                                                onAtualizarItens(novosItens);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            id={`quantidade-orcada-${id}`}
                                            className="form-control"
                                            value={item.quantidadeOrcada}
                                            min={0}
                                            disabled={desabilitado}
                                            onChange={(e) => {
                                                const novosItens = [...itensSelecionados];
                                                novosItens[id].quantidadeOrcada = parseInt(
                                                    e.target.value
                                                );
                                                onAtualizarItens(novosItens);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            id={`valor-unit-orcamentos-${id}`}
                                            className="form-control"
                                            value={item.valorUnitario}
                                            disabled={desabilitado}
                                            onChange={(e) => {
                                                mascaraMoeda(e);
                                                const novosItens = [...itensSelecionados];
                                                const newValue = e.target.value;
                                                novosItens[id].valorUnitario = newValue;
                                                const valorOrcamentoAtualizado = calcularValorOrcamento(novosItens[id].quantidadeOrcada || 0, newValue);
                                                novosItens[id].valorOrcamento = valorOrcamentoAtualizado.toString();
                                                novosItens[id].valorAjustado = valorOrcamentoAtualizado.toString();
                                                onAtualizarItens(novosItens);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            id={`valor-orcamento-${id}`}
                                            className="form-control"
                                            value={formatarMoedaBrasileira(valorOrcamento)}
                                            disabled
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            id={`valor-ajustado-${id}`}
                                            className="form-control"
                                            value={formatarMoedaBrasileira(valorOrcamento)}
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <textarea
                                            id={`observacoes-${id}`}
                                            className="form-control"
                                            disabled={desabilitado}
                                            value={item.observacoes || ""}
                                            rows={1}
                                            style={{ resize: 'none', overflow: 'hidden' }}
                                            onChange={(e) => {
                                                const novosItens = [...itensSelecionados];
                                                novosItens[id].observacoes = e.target.value;
                                                onAtualizarItens(novosItens);
                                                e.target.style.height = 'auto';
                                                e.target.style.height = `${e.target.scrollHeight}px`;
                                            }}
                                        />
                                    </td>

                                    <td>
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() => onRemoverItem(id)}
                                        >
                                            <IoMdRemove />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        );
    } else if (mode === "view" && viewProps) {
        const { onAtualizarItens, itensSelecionados, onRemoverItem } =
            viewProps;
        return (
            <>
                <table className="table table-striped text-center table-hover table-bordered border-lightinfo">
                    <thead>
                        <tr className="table-primary text-center">
                            <th scope="col">Código do orçamento</th>
                            <th scope="col">Item orçamento</th>
                            <th scope="col">Unidade orçada</th>
                            <th scope="col">Quantidade orçada</th>
                            <th scope="col">Vlr unitário do orçamento</th>
                            <th scope="col">Valor orçamento</th>
                            <th scope="col">Valor ajustado</th>
                            <th scope="col">Observações</th>
                            <th scope="col">Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {itensSelecionados.map((item, id) => {
                            const quantidadeOrcada =
                                item.quantidadeOrcada !== undefined ? item.quantidadeOrcada : 0;
                            const valorOrcamento = calcularValorOrcamento(quantidadeOrcada, item.valorUnitario || "0");
                            itensSelecionados[id].valorAjustado = valorOrcamento.toFixed(2).toString();
                            itensSelecionados[id].valorOrcamento = valorOrcamento.toFixed(2).toString();
                            const asteriscos = (item.descricao.match(/\*/g) || []).length;
                            const linhaClasse = asteriscos === 2 ? 'linha-vermelha' : (asteriscos === 1 ? 'linha-verde' : '');
                            const itemAntigo = !!item.itemOrcamento;
                            return (
                                <tr>
                                    <td className={linhaClasse}>{item.codigo}</td>
                                    <td className={linhaClasse}>{item.descricao}</td>
                                    <td className={linhaClasse}>
                                        <input
                                            type="text"
                                            id={`unidade-orcada-${id}`}
                                            className="form-control"
                                            disabled={desabilitado || asteriscos == 2}
                                            value={item.unidadeOrcada || ""}
                                            onChange={(e) => {
                                                const novosItens = [...itensSelecionados];
                                                novosItens[id].unidadeOrcada = e.target.value;
                                                onAtualizarItens(novosItens);
                                            }}
                                        />
                                    </td>
                                    <td className={linhaClasse}>
                                        <input
                                            type="number"
                                            id={`quantidade-orcada-${id}`}
                                            className="form-control"
                                            value={item.quantidadeOrcada}
                                            min={0}
                                            disabled={desabilitado || asteriscos == 2}
                                            onChange={(e) => {
                                                const novosItens = [...itensSelecionados];
                                                novosItens[id].quantidadeOrcada = parseInt(
                                                    e.target.value
                                                );
                                                onAtualizarItens(novosItens);
                                            }}
                                        />
                                    </td>
                                    <td className={linhaClasse}>
                                        <input
                                            type="text"
                                            id={`valor-unit-orcamentos-${id}`}
                                            className="form-control"
                                            value={item.valorUnitario}
                                            disabled={desabilitado || asteriscos == 2}
                                            onChange={(e) => {
                                                mascaraMoeda(e);
                                                const novosItens = [...itensSelecionados];
                                                const newValue = e.target.value;
                                                novosItens[id].valorUnitario = newValue;
                                                const valorOrcamentoAtualizado = calcularValorOrcamento(novosItens[id].quantidadeOrcada || 0, newValue);
                                                novosItens[id].valorOrcamento = valorOrcamentoAtualizado.toString();
                                                novosItens[id].valorAjustado = valorOrcamentoAtualizado.toString();
                                                onAtualizarItens(novosItens);
                                            }}
                                        />
                                    </td>
                                    <td className={linhaClasse}>
                                        <input
                                            type="text"
                                            id={`valor-orcamento-${id}`}
                                            className="form-control"
                                            value={formatarMoedaBrasileira(valorOrcamento)}
                                            disabled
                                            readOnly
                                        />
                                    </td>
                                    <td className={linhaClasse}>
                                        <input
                                            type="text"
                                            id={`valor-ajustado-${id}`}
                                            className="form-control"
                                            value={formatarMoedaBrasileira(valorOrcamento)}
                                            disabled
                                        />
                                    </td>
                                    <td className={linhaClasse}>
                                        <textarea
                                            id={`observacoes-${id}`}
                                            className="form-control"
                                            disabled={desabilitado || asteriscos == 2}
                                            value={item.observacoes || ""}
                                            rows={1}
                                            style={{ resize: 'none', overflow: 'hidden' }}
                                            onChange={(e) => {
                                                const novosItens = [...itensSelecionados];
                                                novosItens[id].observacoes = e.target.value;
                                                onAtualizarItens(novosItens);
                                                e.target.style.height = 'auto';
                                                e.target.style.height = `${e.target.scrollHeight}px`;
                                            }}
                                        />
                                    </td>

                                    <td>
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() => onRemoverItem(id, itemAntigo)}
                                            disabled={asteriscos == 2 || desabilitado}
                                        >
                                            <IoMdRemove />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="w-25">
                    <label htmlFor="valorOrcamentoAjustado">
                        Valor total do orçamento ajustado:
                    </label>
                    <input
                        className="form-control"
                        id="valorOrcamentoAjustado"
                        type="text"
                        placeholder="R$ 0,00"
                        aria-label="Disabled input example"
                        disabled
                        value={formatarMoedaBrasileira(somaTotalAjustado)}
                    />
                </div>
            </>
        );
    }

    return null;
};

export default TabelaOrcamentos;
