import React, { useEffect, useState } from "react";
import { useFormData } from "../../Hooks/useFormData";
import {
 CriarFormularioProps,
 FormularioProps,
 VisualizarFormularioProps,
} from "../../Interface/IFormulario";
import { getDataAtual } from "../../Utils/DataAtual";
import { formatarMoedaBrasileira } from "../../Utils/FormatarMoedaBrasileira";
import InputAutoComplete from "../InputAutocomplete";

const Formulario: React.FC<FormularioProps & { mostrarPrazoContratacao: boolean }> = (props) => {
 const { mode, temPermissao, mostrarPrazoContratacao } = props;
 const [empresaSelecionada, setEmpresaSelecionada] = useState<string>("");
 const [obraSelecionada, setObraSelecionada] = useState<string>("");
 const [tipoSolicitacao, setTipoSolicitacao] = useState<string>("");
 const { fornecedores, estado } = useFormData(
  empresaSelecionada,
  obraSelecionada
 );
 const dataAtual = getDataAtual();

 const { tipoDeSolicitacao, onDadosTipoSolicitacao } =
  props as FormularioProps;

 const atualizacaoTipoSolicitacao = (
  event: React.ChangeEvent<HTMLSelectElement>
 ) => {
  const tipoDeSolicitacaoNome = event.target.value;
  setTipoSolicitacao(tipoDeSolicitacaoNome);
  if (Array.isArray(tipoDeSolicitacao)) {
   const solicitacaoEncontrada = tipoDeSolicitacao.find(
    (solicitacao) => solicitacao.tipoSolicitacao === tipoDeSolicitacaoNome
   );
   if (solicitacaoEncontrada && onDadosTipoSolicitacao) {
    onDadosTipoSolicitacao(
     solicitacaoEncontrada.id,
     solicitacaoEncontrada.tipoSolicitacao
    );
   }
  }
 };

 useEffect(() => {
  const { onEstadoObra } = props as CriarFormularioProps;

  if (estado !== "" && mode === "create") {
   onEstadoObra(estado);
  }
 }, [estado]);

 if (mode === "create") {
  const {
   desabilitado,
   empresasObras,
   tipoDeSolicitacao,
   tipoDeServicos,
   valorPagar,
   processoAnterior,
   contratoUAU,
   haveraDistrato,
   onHaveraDistrato,
   onFornecedorSelecionado,
   onValorPagar,
   onProcessoAnterior,
   onContratoUAU,
   onDadosDaEmpresa,
   onDadosDaObra,
   onEstadoObra,
   onTipoServicoSelecionado,
  } = props as CriarFormularioProps;

  const updateObra = (obraId: string) => {
   setObraSelecionada(obraId);
   const obraQueFoiSelecionada = empresasObras.find(
    (obra) => obra.codigoObra === obraId
   );

   if (obraQueFoiSelecionada) {
    setEmpresaSelecionada(obraQueFoiSelecionada.codigoEmpresa.toString());
    onDadosDaObra(
     obraQueFoiSelecionada.codigoObra.toString(),
     obraQueFoiSelecionada.codigoObraFiscal.toString(),
     obraQueFoiSelecionada.nomeObra.toString()
    );
    onDadosDaEmpresa(
     obraQueFoiSelecionada.codigoEmpresa.toString(),
     obraQueFoiSelecionada.nomeEmpresa.toString(),
     obraQueFoiSelecionada.cnpj.toString()
    );
   }
   onEstadoObra(estado);
  };

  const obras = empresasObras.map((obra) => {
   return {
    nome: `${obra.codigoObra} - ${obra.nomeObra}`,
    codigo: obra.codigoObra,
   };
  });


  const atualizacaoTipoServico = (
   event: React.ChangeEvent<HTMLSelectElement>
  ) => {
   const tipoServicoSelecionado = event.target.value;

   const servicoEncontrado = tipoDeServicos.find(
    (servico) => servico.nomeServico === tipoServicoSelecionado
   );

   if (servicoEncontrado && servicoEncontrado.codigoServicoBI) {
    onTipoServicoSelecionado(
     servicoEncontrado.codigoServicoBI,
     servicoEncontrado.id,
     servicoEncontrado.nomeServico
    );
   }
  };

  const temp = empresaSelecionada
   ? empresasObras.find((obra) => obra.codigoEmpresa === +empresaSelecionada)
   : null;

  const inputEmpresa = temp
   ? `${temp.codigoEmpresa} - ${temp.nomeEmpresa}`
   : "";

  return (
   <form className="d-flex w-100 flex-column gap-3 needs-validation">
    <div className="d-flex gap-4">
     <div className="form-floating w-50">
      <input className="form-select" value={inputEmpresa} disabled />
      <label className="text-primary" htmlFor="CampoEmpresa">
       Empresa
      </label>
     </div>
     <InputAutoComplete obras={obras} update={updateObra} />
    </div>
    <div className="d-flex gap-4">
     <div className="form-floating w-25 h-25">
      <select
       className="form-select form-control"
       id="CampoTipoSolicitacao"
       aria-label="Floating label select example"
       disabled={desabilitado}
       value={tipoSolicitacao}
       onChange={atualizacaoTipoSolicitacao}
      >
       <option value="">Selecione...</option>
       {tipoDeSolicitacao.map((solicitacao) => (
        <option key={solicitacao.id} value={solicitacao.tipoSolicitacao}>
         {solicitacao.tipoSolicitacao}
        </option>
       ))}
      </select>
      <label className="text-primary" htmlFor="CampoTipoSolicitacao">
       Tipo de solicitação<span className="text-danger">*</span>
      </label>
     </div>
     <div className="form-floating w-25">
      <select
       className="form-select form-control"
       id="CampoTipoServico"
       aria-label="Floating label select example"
       disabled={desabilitado}
       onChange={atualizacaoTipoServico}
      >
       <option value="">Selecione...</option>
       {tipoDeServicos
        .slice()
        .sort((a, b) => a.nomeServico.localeCompare(b.nomeServico))
        .map((servico) => (
         <option key={servico.id} value={servico.nomeServico}>
          {servico.nomeServico.length >= 140
           ? servico.nomeServico.substring(0, 140)
           : servico.nomeServico}
         </option>
        ))}
      </select>
      <label className="text-primary" htmlFor="CampoTipoServico">
       Tipo de serviço<span className="text-danger">*</span>
      </label>
     </div>
     <div className="form-floating mb-3 w-25">
      <input
       type="text"
       className="form-control"
       id="CampoEstado"
       disabled
       value={estado}
      />
      <label className="text-primary" htmlFor="CampoEstado">
       Estado
      </label>
     </div>
     <div className="form-floating mb-3 w-25">
      <input
       type="date"
       className="form-control"
       id="CampoDataPedido"
       disabled
       value={dataAtual}
      />
      <label className="text-primary" htmlFor="CampoDataPedido">
       Data do pedido
      </label>
     </div>
    </div>
    {tipoSolicitacao !== "Contratação Inicial" &&
     tipoSolicitacao !== "" &&
     tipoSolicitacao !== null && (
      <>
       <div className="d-flex gap-4">
        <div className="form-floating w-25">
         <select
          className="form-select form-control"
          id="CampoFornecedor"
          aria-label="Floating label select example"
          disabled={desabilitado}
          onChange={onFornecedorSelecionado}
         >
          <option value="">Selecione...</option>
          {fornecedores
           .slice()
           .sort((a: any, b: any) =>
            a.nomeFornecedor.localeCompare(b.nomeFornecedor)
           )
           .map((fornecedor: any) => (
            <option key={fornecedor.id} value={fornecedor.nomeFornecedor}>
             {fornecedor.nomeFornecedor}
            </option>
           ))}
         </select>
         <label className="text-primary" htmlFor="CampoFornecedor">
          Fornecedor
         </label>
        </div>
        <div className="form-floating w-25">
         <input
          type="number"
          className="form-control"
          id="CampoContratacaoInicial"
          disabled={desabilitado}
          onChange={onProcessoAnterior}
          value={processoAnterior}
         />
         <label
          className="text-primary"
          htmlFor="CampoContratacaoInicial"
         >
          N° da contratação inicial
         </label>
        </div>
        <div className="form-floating w-25">
         <input
          type="number"
          className="form-control"
          id="CampoContratoUAU"
          aria-label="Floating label select example"
          disabled={desabilitado}
          onChange={onContratoUAU}
          value={contratoUAU}
         />
         <label className="text-primary" htmlFor="CampoContratoUAU">
          N° do contrato UAU
         </label>
        </div>
        <div className="form-floating w-25">
         <select
          className="form-select"
          id="CampoDistrato"
          aria-label="Floating label select example"
          disabled={desabilitado}
          value={haveraDistrato}
          onChange={onHaveraDistrato}
         >
          <option value="">Selecione...</option>
          <option value="1">Sim</option>
          <option value="2">Não</option>
         </select>
         <label className="text-primary" htmlFor="CampoDistrato">
          Haverá distrato?
         </label>
        </div>
       </div>
       {haveraDistrato === "1" && (
        <div>
         <div className="form-floating w-25">
          <input
           className="form-control"
           id="valorContratacao"
           value={formatarMoedaBrasileira(valorPagar)}
           onChange={onValorPagar}
          />
          <label className="text-primary" htmlFor="ValorContratacao">
           Qual o valor? <span className="text-danger">*</span>
          </label>
         </div>
        </div>
       )}
      </>
     )
    }
   </form >
  );
 } else if (mode === "view") {
  const { dadosFormulario, tipoDeSolicitacao } =
   props as VisualizarFormularioProps;
  return (
   <form className="d-flex w-100 flex-column gap-3 needs-validation">
    <div className="d-flex gap-4">
     <div className="form-floating w-50">
      <input
       type="text"
       className="form-control"
       id="CampoEmpresa"
       value={`${dadosFormulario.codigoEmpresa} - ${dadosFormulario.razaoSocial}`}
       disabled
      />
      <label className="text-primary" htmlFor="CampoEmpresa">
       Empresa
      </label>
     </div>
     <div className="form-floating w-50">
      <input
       type="text"
       className="form-control"
       id="CampoObra"
       value={`${dadosFormulario.codigoObra} - ${dadosFormulario.nomeEmpreendimento}`}
       disabled
      />
      <label className="text-primary" htmlFor="CampoObra">
       Obra
      </label>
     </div>
    </div>
    <div className="d-flex gap-4">
     <div className="form-floating mb-3 w-25">
      <input
       type="text"
       className="form-control"
       id="CampoEstado"
       disabled
       value={dadosFormulario.estado}
      />
      <label className="text-primary" htmlFor="CampoEstado">
       Estado
      </label>
     </div>
     <div className="form-floating mb-3 w-25">
      <input
       type="date"
       className="form-control"
       id="CampoDataPedido"
       disabled
       value={dadosFormulario.dataPedido}
      />
      <label className="text-primary" htmlFor="CampoDataPedido">
       Data do pedido
      </label>
     </div>
     <div className="form-floating w-25">
      <select
       className="form-select form-control"
       id="CampoTipoSolicitacao"
       aria-label="Floating label select example"
       disabled={!temPermissao}
       value={tipoSolicitacao}
       onChange={temPermissao ? atualizacaoTipoSolicitacao : undefined}
      >
       <option value="">{dadosFormulario.tipoSolicitacao}</option>
       {temPermissao &&
        tipoDeSolicitacao &&
        tipoDeSolicitacao.map((solicitacao) => (
         <option
          key={solicitacao.id}
          value={solicitacao.tipoSolicitacao}
         >
          {solicitacao.tipoSolicitacao}
         </option>
        ))}
      </select>
      <label className="text-primary" htmlFor="CampoTipoSolicitacao">
       Tipo de solicitação
       <span className="text-danger">*</span>
      </label>
     </div>
     <div className="form-floating w-25">
      <input
       type="text"
       className="form-control"
       id="CampoTipoServico"
       value={dadosFormulario.descricaoServico}
       disabled
      />
      <label className="text-primary" htmlFor="CampoTipoServico">
       Tipo de serviço
      </label>
     </div>
    </div>
    {dadosFormulario.tipoSolicitacao !== "Contratação Inicial" && (
     <>
      <div className="d-flex gap-4">
       <div className="form-floating w-25">
        <input
         type="text"
         className="form-control"
         id="CampoFornecedor"
         value={dadosFormulario.fornecedor || ""}
         disabled
        />
        <label className="text-primary" htmlFor="CampoFornecedor">
         Fornecedor
        </label>
       </div>
       <div className="form-floating w-25">
        <input
         type="text"
         className="form-control"
         id="CampoContratacaoInicial"
         value={dadosFormulario.processoAnterior || ""}
         disabled
        />
        <label
         className="text-primary"
         htmlFor="CampoContratacaoInicial"
        >
         N° da contratação inicial
        </label>
       </div>
       <div className="form-floating w-25">
        <input
         type="text"
         className="form-control"
         id="CampoContratoUAU"
         value={dadosFormulario.contratoUAU || ""}
         disabled
        />
        <label className="text-primary" htmlFor="CampoContratoUAU">
         N° do contrato UAU
        </label>
       </div>
       <div className="form-floating w-25">
        <input
         type="text"
         className="form-control"
         id="CampoDistrato"
         value={dadosFormulario.distrato ? "Sim" : "Não"}
         disabled
        />
        <label className="text-primary" htmlFor="CampoDistrato">
         Haverá distrato?
        </label>
       </div>
      </div>
      {dadosFormulario.distrato && dadosFormulario.valorPagar !== null && (
       <div>
        <div className="form-floating w-25">
         <input
          className="form-control"
          id="valorContratacao"
          value={formatarMoedaBrasileira(dadosFormulario.valorPagar)}
          disabled
         />
         <label className="text-primary" htmlFor="ValorContratacao">
          Qual o valor? <span className="text-danger">*</span>
         </label>
        </div>
       </div>
      )}
     </>
    )}
   </form>
  );
 }
 return null;
};

export default Formulario;
