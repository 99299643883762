export function formatarMoedaBrasileira(valor: number | string): string {
 if (typeof valor === 'string') {
  valor = parseFloat(valor.replace(/[R$.,\s]/g, '')) / 100;
 }

 const valorFormatado = valor.toFixed(2);

 return parseFloat(valorFormatado).toLocaleString('pt-BR', {
  style: 'currency',
  currency: 'BRL',
 });
}

export function formatarMoedaBrasileiraView(valor: number | string): string {
 if (typeof valor === 'string') {
  valor = parseFloat(valor.replace(/[R$.,\s]/g, '')) / 100;
 } else {
  valor = valor / 100;
 }

 const valorFormatado = valor.toFixed(2);

 return parseFloat(valorFormatado).toLocaleString('pt-BR', {
  style: 'currency',
  currency: 'BRL',
 });
}

export function mascaraMoeda(event: React.ChangeEvent<HTMLInputElement>): void {
 const input = event.target;
 const onlyDigits = input.value
  .split("")
  .filter((s) => /\d/.test(s))
  .join("")
  .padStart(3, "0");

 const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);

 input.value = formatarMoedaBrasileira(digitsFloat);
}
