import React, { useCallback, useEffect, useState } from "react";
import {
 CriarItemSemOrcamentoProps,
 ItemSemOrcamentoProps,
 VisualizarItemSemOrcamentoProps,
} from "../../Interface/IItemSemOrcamento";

const ItemSemOrcamento: React.FC<ItemSemOrcamentoProps> = (props) => {
 const { mode } = props;
 const isCreateMode = mode === "create";

 const createProps = isCreateMode
  ? (props as CriarItemSemOrcamentoProps)
  : null;
 const visualizarProps = !isCreateMode
  ? (props as VisualizarItemSemOrcamentoProps)
  : null;

 const [orcamentoExistente, setOrcamentoExistente] = useState<boolean>(false);
 const [justificativa, setJustificativa] = useState<string>("");

 const handleOrcamentoChange = useCallback(() => {
  if (createProps && createProps.onOrcamentoChange) {
   createProps.onOrcamentoChange(orcamentoExistente, justificativa);
  }
 }, [orcamentoExistente, justificativa, createProps]);

 useEffect(() => {
  handleOrcamentoChange();
 }, [orcamentoExistente, justificativa, handleOrcamentoChange]);

 if (isCreateMode && createProps) {
  const { desabilitado } = createProps;

  const handleOpcaoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
   const isSim = e.target.id === "Sim";
   setOrcamentoExistente(isSim);

   if (!isSim) {
    setJustificativa("");
   }
  };

  const handleJustificativaChange = (
   e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
   setJustificativa(e.target.value);
  };

  return (
   <>
    <div className="d-flex flex-column gap-4">
     <p className="text-primary">
      Existe item sem orçamento nesse pedido?
     </p>
     <div className="d-flex gap-2">
      <input
       type="radio"
       className="btn-check"
       name="OrcamentoExistente"
       id="Nao"
       disabled={desabilitado}
       onChange={handleOpcaoChange}
       checked={!orcamentoExistente}
      />
      <label className="btn btn-outline-primary bg-none " htmlFor="Nao">
       Não
      </label>

      <input
       type="radio"
       className="btn-check"
       name="OrcamentoExistente"
       id="Sim"
       disabled={desabilitado}
       onChange={handleOpcaoChange}
       checked={orcamentoExistente}
      />
      <label className="btn btn-outline-primary" htmlFor="Sim">
       Sim
      </label>
     </div>
     {orcamentoExistente && (
      <div className="form-floating mt-3">
       <textarea
        className="form-control w-100 h-100"
        id="justificativa"
        value={justificativa}
        onChange={handleJustificativaChange}
        disabled={desabilitado}
       ></textarea>
       <label
        className="lh-1 fs-6 text-primary text-wrap"
        htmlFor="justificativa"
       >
        Justificativa<span className="text-danger">*</span>
       </label>
       {justificativa.trim() === "" && (
        <small className="text-danger">
         A justificativa é obrigatória.
        </small>
       )}
      </div>
     )}
    </div>
   </>
  );
 } else if (mode === "view" && visualizarProps) {
  const { itensSemOrcamento } = visualizarProps;
  const orcamentoExistente = itensSemOrcamento.itemSemOrcamento;
  return (
   <>
    <div className="d-flex flex-column gap-4">
     <p className="text-primary">
      Existe item sem orçamento nesse pedido?
     </p>
     <div className="d-flex gap-2">
      <input
       type="radio"
       className="btn-check"
       name="OrcamentoExistente"
       id="Nao"
       disabled
       checked={!itensSemOrcamento.itemSemOrcamento}
      />
      <label className="btn btn-outline-primary bg-none " htmlFor="Nao">
       Não
      </label>

      <input
       type="radio"
       className="btn-check"
       name="OrcamentoExistente"
       id="Sim"
       disabled
       checked={itensSemOrcamento.itemSemOrcamento}
      />
      <label className="btn btn-outline-primary" htmlFor="Sim">
       Sim
      </label>
     </div>
     {orcamentoExistente && (
      <div className="form-floating mt-3">
       <textarea
        className="form-control w-100 h-100"
        id="justificativa"
        value={itensSemOrcamento.justificativa}
        disabled
       ></textarea>
       <label
        className="lh-1 fs-6 text-primary text-wrap"
        htmlFor="justificativa"
       >
        Justificativa<span className="text-danger">*</span>
       </label>
      </div>
     )}
    </div>
   </>
  );
 }
 return null;
};

export default ItemSemOrcamento;
