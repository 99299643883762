import { AxiosResponse } from "axios";
import { Anexo } from "../../../Interface/IAnexos";
import { AtualizarPedidoProps } from "../../../Interface/IAtualizarPedido";
import { CancelarPedido } from "../../../Interface/ICancelamento";
import { FiltrarPedido } from "../../../Interface/IFiltrarPedido";
import {
    FinalizarPedido,
    VerificaDivergenciaContratual,
} from "../../../Interface/IFinalizarPedido";
import { Fornecedor } from "../../../Interface/IFornecedorUAU";
import { AtualizacaoFornecedorProps } from "../../../Interface/IResumoContratacao";
import client from "../../config/client";

export const getObterEmpresaObraPorMatricula = async (
    codigoUsuario: string
) => {
    try {
        const res = await client.get(
            `Pedido/obter-obras-por-matricula?codigoUsuario=${codigoUsuario}`
        );
        return res;
    } catch (error: any) {
        throw new Error("Erro ao carregar os dados da empresa", error.message);
    }
};
export const getTipoDeSolicitacao = async () => {
    try {
        const res = await client.get("Pedido/listar-tipos-solicitacao");
        return res;
    } catch (error: any) {
        throw new Error("Erro ao carregar os dados da empresa", error.message);
    }
};
export const getListaDeJustificativaPedidoEmergencial = async () => {
    try {
        const res = await client.get("Pedido/listar-justificativa-emergencial");
        return res;
    } catch (error: any) {
        throw new Error("Erro ao carregar os dados da empresa", error.message);
    }
};
export const getDetalhesDaObra = async (
    codigoEmpresa: string,
    codigoObra: string
) => {
    try {
        const res = await client.get(
            `Pedido/buscar-dados-obra?empresa=${codigoEmpresa}&obra=${codigoObra}`
        );
        return res;
    } catch (error: any) {
        throw new Error("Erro ao carregar os dados da empresa", error.message);
    }
};
export const getListaTipoAnexo = async () => {
    try {
        const res = await client.get(`Pedido/listar-tipos-anexos`);
        return res;
    } catch (error: any) {
        throw new Error("Erro ao carregar os tipos de anexo", error.message);
    }
};
export const getObterPedidosPorMatricula = async (codigoUsuario: string) => {
    try {
        const res = await client.get(
            `Pedido/obter-pedidos-por-matricula?codigoUsuario=${codigoUsuario}`
        );
        return res;
    } catch (error: any) {
        throw new Error(
            "Erro ao carregar os pedidos por matrícula do usuário",
            error.message
        );
    }
};

export const getObterDetalhesDoPedido = async (numeroPedido: number) => {
    try {
        const res = await client.get(
            `Pedido/detalhes-pedido?numeroPedido=${numeroPedido}`
        );
        return res;
    } catch (error: any) {
        throw new Error("Erro ao carregar os detalhes do pedido", error.message);
    }
};
export const getObterHistoricoDoPedido = async (numeroPedido: number) => {
    try {
        const res = await client.get(
            `Pedido/buscar-historico-pedido?codigoPedido=${numeroPedido}`
        );
        return res;
    } catch (error: any) {
        throw new Error("Erro ao carregar os detalhes do pedido", error.message);
    }
};

export const getObterDownloadAnexo = async (url: string) => {
    try {
        const res = await client.get(
            `Pedido/obter-url-anexo?identificadorAnexo=${url}`
        );
        return res;
    } catch (error: any) {
        throw new Error("Erro ao carregar os detalhes do pedido", error.message);
    }
};

export const getObterFornecedoresNoUAU = async (
    params: string
): Promise<Fornecedor[]> => {
    try {
        const queryParam = `nomeFornecedor=${isNaN(Number(params)) ? params : ""
            }&cnpj=${isNaN(Number(params)) ? "" : params}`;

        const res: AxiosResponse<Fornecedor[]> = await client.get(
            `Pedido/obter-fornecedores-UAU?${queryParam}`
        );

        return res.data;
    } catch (error: any) {
        console.error("Erro ao carregar os detalhes do pedido:", error.message);
        throw new Error("Erro ao carregar os detalhes do pedido: " + error.message);
    }
};

export const postCadastrar = async (formData: {}): Promise<any> => {
    try {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        const res = await client.post<any, any>(
            "Pedido/cadastrar",
            formData,
            config
        );
        return res;
    } catch (error: any) {
        throw new Error("Erro ao cadastrar: " + error.message);
    }
};

export const postFiltrar = async (formData: FiltrarPedido): Promise<any> => {
    try {
        const headers = {
            "Content-Type": "application/json",
        };
        const res = await client.post<any, any>(
            "Pedido/pesquisar",
            formData,
            headers
        );
        return res;
    } catch (error: any) {
        throw new Error("Erro ao filtrar: " + error.message);
    }
};

export const postVerificaDivergenciaContratual = async (
    formData: VerificaDivergenciaContratual
): Promise<VerificaDivergenciaContratual> => {
    try {
        const config = {
            headers: {
                accept: "text/plain",
                "Content-Type": "application/json",
            },
        };
        const res = await client.post<VerificaDivergenciaContratual, any>(
            "Pedido/consultar-divergencia-contrato-UAU",
            formData,
            config
        );
        return res;
    } catch (error: any) {
        throw new Error("Erro ao filtrar: " + error.message);
    }
};

export const postFinalizar = async (
    formData: FinalizarPedido
): Promise<FinalizarPedido> => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        }
        const res = await client.post<FinalizarPedido, any>(
            "Pedido/finalizar-pedido",
            formData,
            config
        );
        return res;
    } catch (error: any) {
        throw new Error("Erro ao filtrar: " + error.message);
    }
};

export const postCancelar = async (
    formData: CancelarPedido
): Promise<CancelarPedido> => {
    try {
        const headers = {
            "Content-Type": "application/json",
        };
        const res = await client.post<CancelarPedido, any>(
            "Pedido/cancelar-pedido",
            formData,
            headers
        );
        return res;
    } catch (error: any) {
        throw new Error("Erro ao filtrar: " + error.message);
    }
};

export const putAtualizarFornecedor = async (
    formData: AtualizacaoFornecedorProps
): Promise<AtualizacaoFornecedorProps> => {
    try {
        const headers = {
            "Content-Type": "application/json",
        };
        const res = await client.put<AtualizacaoFornecedorProps, any>(
            "Pedido/atualizar-pedido",
            formData,
            headers
        );
        return res;
    } catch (error: any) {
        throw new Error("Erro ao filtrar: " + error.message);
    }
};

export const putAtualizarAnexoPedido = async (
    codigoPedido: number,
    arquivos: Anexo[],
): Promise<AxiosResponse<any>> => {
    try {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        const novosAnexos = arquivos.filter((item) => item.arquivo)

        const formData = new FormData();
        novosAnexos.forEach((item) => {
            const nomeArquivo = `${item.tipoDocumento}_${item.nomeArquivo}`
            formData.append('arquivos', item.arquivo!, nomeArquivo);
        });
        const res: AxiosResponse<any> = await client.put(
            `Pedido/atualizar-anexo-pedido?codigoPedido=${codigoPedido}`,
            formData,
            config,
        );
        return res;
    } catch (error: any) {
        throw new Error("Erro ao atualizar anexos do pedido: " + error.message);
    }
};

export const putAtualizarPedido = async (
    formData: AtualizarPedidoProps
): Promise<AtualizarPedidoProps> => {
    console.log(formData)
    try {
        const headers = {
            "Content-Type": "application/json",
        };
        const res = await client.put<AtualizarPedidoProps, any>(
            "Pedido/atualizar-pedido",
            formData,
            headers
        );
        return res;
    } catch (error: any) {
        throw new Error("Erro ao filtrar: " + error.message);
    }
};