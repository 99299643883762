import React, { useEffect } from "react";
import { IoMdRemove } from "react-icons/io";
import { useTabelaQuantitativos } from "../../Hooks/useTabelaQuantitativos";
import {
  CriarTabelaQuantitativosProps,
  TabelaQuantitativosProps,
  VisualizarTabelaQuantitativosProps
} from "../../Interface/ITabelaQuantitativo";

const TabelaQuantitativos: React.FC<TabelaQuantitativosProps> = (props) => {
  const { mode, desabilitado } = props;

  const createProps =
    mode === "create" ? (props as CriarTabelaQuantitativosProps) : null;

  const viewProps =
    mode === "view" ? (props as VisualizarTabelaQuantitativosProps) : null;

  const { dadosQuantitativos } = props as VisualizarTabelaQuantitativosProps;
  const createHook = useTabelaQuantitativos(
    mode,
    createProps?.empresa || viewProps?.empresaObra.codigoEmpresa || "",
    createProps?.obra || viewProps?.empresaObra.codigoObra || "",
    createProps?.itensSelecionados || dadosQuantitativos,
    createProps?.onAtualizarItens || viewProps?.onAtualizarItens || (() => { })
  );

  const { codigoPLM, dadosTabela, handleInputChange, setDadosTabela } = createHook;

  useEffect(() => {
    if (dadosQuantitativos && Array.isArray(dadosQuantitativos)) {
      setDadosTabela(dadosQuantitativos);
    }
  }, [dadosQuantitativos, setDadosTabela]);

  if (mode === "create" && createProps) {
    const { desabilitado, onRemoverItem } = createProps;
    const { codigoPLM, dadosTabela, handleInputChange } = createHook;

    return (
      <table className="table table-striped text-center table-hover table-bordered border-lightinfo">
        <thead>
          <tr className="table-primary text-center">
            <th scope="col">Código do item</th>
            <th scope="col">Descrição do serviço</th>
            <th scope="col">Unidade de Medida</th>
            <th scope="col">Quantidade Solicitada</th>
            <th scope="col">Código PLM</th>
            <th scope="col">Observações</th>
            <th scope="col">Ação</th>
          </tr>
        </thead>
        <tbody>
          {dadosTabela &&
            Array.isArray(dadosTabela) &&
            dadosTabela.map((item, id) => {
              return (
                <tr key={id}>
                  <td>{item.codigo}</td>
                  <td>{item.descricao}</td>
                  <td>{item.unidadeComposicao}</td>
                  <td>
                    <input
                      type="number"
                      min={0}
                      id={`quantidadeSolicitada-${id}`}
                      className="form-control"
                      disabled={desabilitado}
                      value={item.quantidadeSolicitada}
                      onChange={(e) =>
                        handleInputChange(id, "quantidadeSolicitada", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <select
                      className="form-select"
                      aria-label="Floating label select example"
                      disabled={desabilitado}
                      value={String(item.codigoPLM)}
                      onChange={(e) =>
                        handleInputChange(id, "codigoPLM", e.target.value)
                      }
                    >
                      <option value="">Selecione o código PLM</option>
                      {codigoPLM &&
                        Array.isArray(codigoPLM) &&
                        codigoPLM
                          .slice()
                          .sort((a: any, b: any) =>
                            a.codigoPLM.localeCompare(b.codigoPLM)
                          )
                          .map((codigo: any) => (
                            <option key={codigo.id} value={codigo.codigoPLM}>
                              {codigo.codigoPLM + " - " + codigo.descricao}
                            </option>
                          ))}
                    </select>
                  </td>
                  <td>
                    <textarea
                      className="form-control"
                      id={`observacoes-${id}`}
                      disabled={desabilitado}
                      style={{ resize: "none", overflow: "hidden" }}
                      rows={1}
                      value={item.observacoes}
                      onChange={(e) => {
                        handleInputChange(id, "observacoes", e.target.value);
                        const target = e.target as HTMLTextAreaElement;
                        target.style.height = "auto";
                        target.style.height = `${target.scrollHeight}px`;
                      }}
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => onRemoverItem(id)}
                    >
                      <IoMdRemove />
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  } else if (mode === "view" && viewProps) {
    const { onRemoverItem } = viewProps
    return (
      <table className="table table-striped text-center table-hover table-bordered border-lightinfo">
        <thead>
          <tr className="table-primary text-center">
            <th scope="col">Código do item</th>
            <th scope="col">Descrição do serviço</th>
            <th scope="col">Unidade de Medida</th>
            <th scope="col">Quantidade Solicitada</th>
            <th scope="col">Código PLM</th>
            <th scope="col">Observações</th>
            <th scope="col">Ação</th>
          </tr>
        </thead>
        <tbody>
          {dadosTabela &&
            Array.isArray(dadosTabela) &&
            dadosTabela.map((item, index) => {
              const asteriscos = (item.descricao.match(/\*/g) || []).length;
              const linhaClasse = asteriscos === 2 ? 'linha-vermelha' : (asteriscos === 1 ? 'linha-verde' : '');
              const itemAntigo = !!item.descricaoComposicao;
              return (
                <tr key={index}>
                  <td className={linhaClasse}>{item.codigo}</td>
                  <td className={linhaClasse}>{item.descricao}</td>
                  <td className={linhaClasse}>{item.unidadeComposicao}</td>
                  <td className={linhaClasse}>
                    <input
                      type="number"
                      min={0}
                      id={`quantidadeSolicitada-${index}`}
                      className="form-control"
                      value={item.quantidadeSolicitada}
                      disabled={desabilitado || asteriscos == 2}
                      onChange={(e) =>
                        handleInputChange(index, "quantidadeSolicitada", e.target.value)
                      }
                    />
                  </td>
                  <td className={linhaClasse}>
                    <select
                      className="form-select"
                      aria-label="Floating label select example"
                      disabled={desabilitado || asteriscos == 2}
                      value={String(item.codigoPLM || "")}
                      onChange={(e) =>
                        handleInputChange(index, "codigoPLM", e.target.value)
                      }
                    >
                      <option value="">Selecione o código PLM</option>
                      {codigoPLM &&
                        Array.isArray(codigoPLM) &&
                        codigoPLM
                          .slice()
                          .sort((a: any, b: any) =>
                            a.codigoPLM.localeCompare(b.codigoPLM)
                          )
                          .map((codigo: any) => (
                            <option key={codigo.id} value={codigo.codigoPLM}>
                              {codigo.codigoPLM + " - " + codigo.descricao}
                            </option>
                          ))}
                    </select>
                  </td>
                  <td className={linhaClasse}>
                    <textarea
                      className="form-control"
                      id={`observacoes-${index}`}
                      disabled={desabilitado || asteriscos == 2}
                      style={{ resize: "none", overflow: "hidden" }}
                      rows={1}
                      value={item.observacoes}
                      onChange={(e) => {
                        handleInputChange(index, "observacoes", e.target.value);
                        const target = e.target as HTMLTextAreaElement;
                        target.style.height = "auto";
                        target.style.height = `${target.scrollHeight}px`;
                      }}
                    />
                  </td>
                  <td>
                    <button type="button" className="btn btn-outline-danger"
                      onClick={() => onRemoverItem(index, itemAntigo)}
                      disabled={asteriscos == 2 || desabilitado}
                    >
                      <IoMdRemove />
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  }
  return null;
};

export default TabelaQuantitativos;
