import { FC, useState } from "react";
import { InputAutoCompleteProps } from "../../Interface/IFormulario";
import { OptionsInput } from '../OptionsInput';

const InputAutoComplete: FC<InputAutoCompleteProps> = (props) => {
 const [inputValue, setInputValue] = useState('');
 const [optionsVisible, setOptionsVisible] = useState(true);
 const { update, obras } = props;

 function handleClick(obra: { nome: string, codigo: string }): void {
  setInputValue(obra.nome);
  update(obra.codigo);
  setOptionsVisible(false)
 };

 const lowerInputValue = inputValue.toLocaleLowerCase();

 const filteredOptions = lowerInputValue.length >= 2 ? obras.filter(
  (obra) => obra.nome.toLocaleLowerCase()
   .includes(lowerInputValue)
 ) : [];

 return (
  <>
   <div className="form-floating w-50 h-50">
    <input
     type="text"
     className="form-control"
     id="autocompleteInput"
     placeholder="Digite o código ou nome da obra"
     value={inputValue}

     onChange={(e) => {
      if (e.target.value.length === 2)
       setOptionsVisible(true);
      setInputValue(e.target.value);
     }}
    />

    {optionsVisible && <OptionsInput obras={filteredOptions} onClick={handleClick} />}

    <label className="text-primary" htmlFor="autocompleteInput">
     Obra <span className="text-danger">*</span>
    </label>
   </div>
  </>
 );
}

export default InputAutoComplete