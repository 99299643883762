export type Estado = {
    [sigla: string]: string;
  };
  
  export const estados: Estado = {
    AC: "Acre",
    AL: "Alagoas",
    AP: "Amapá",
    AM: "Amazonas",
    BA: "Bahia",
    CE: "Ceará",
    DF: "Distrito Federal",
    ES: "Espírito Santo",
    GO: "Goiás",
    MA: "Maranhão",
    MT: "Mato Grosso",
    MS: "Mato Grosso do Sul",
    MG: "Minas Gerais",
    PA: "Pará",
    PB: "Paraíba",
    PR: "Paraná",
    PE: "Pernambuco",
    PI: "Piauí",
    RJ: "Rio de Janeiro",
    RN: "Rio Grande do Norte",
    RS: "Rio Grande do Sul",
    RO: "Rondônia",
    RR: "Roraima",
    SC: "Santa Catarina",
    SP: "São Paulo",
    SE: "Sergipe",
    TO: "Tocantins",
  };
  
  export type Status = {
    [status: string]: string;
  };
  
  export const statusPedido: Status = {
    1: "Em andamento",
    2: "Concluído",
    3: "Cancelado",
  };
  
  export type Etapa = {
    [etapa: string]: string;
  };
  
  export const etapaSolicitacao: Etapa = {
    1: "Solicitação de serviço",
    2: "Aprovação da solicitação do serviço",
    3: "Montagem do mapa",
    4: "Aprovação do mapa",
    5: "Contratação de serviço",
    6: "Aprovação da justificativa",
    7: "Minuta contratual",
  };
  
  export type Responsavel = {
    [responsavel: string]: string;
  };
  
  export const responsavelPelaEtapa: Responsavel = {
    Solicitante: "Solicitante",
    "Aprovador 0": "Aprovador 0",
    "Aprovador 1": "Aprovador 1",
    "Aprovador 2": "Aprovador 2",
    "Aprovador 2 (MDE)": "Aprovador 2 (MDE)",
    "Aprovador 3": "Aprovador 3",
    "Suprimentos 1": "Suprimentos 1",
    "Suprimentos 2": "Suprimentos 2",
    "Suprimentos 3": "Suprimentos 3",
    "Suprimentos 4": "Suprimentos 4",
  };
  