import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertaBootstrap from "../../components/Alertas";
import Anexos from "../../components/Anexos";
import BuscarCampo from "../../components/BuscarCampos";
import OpcaoInsumoComposicao from "../../components/CheckboxesInsumoComposicao";
import DataAssinatura from "../../components/DataAssinatura";
import DetalhesPedido from "../../components/DetalhesPedido";
import Formulario from "../../components/Formulario";
import ItemSemOrcamento from "../../components/ItemSemOrcamento";
import TabelaOrcamentos from "../../components/TabelaOrcamentos";
import TabelaQuantitativos from "../../components/TabelaQuantitativos";
import useAlert from "../../Hooks/useAlert";
import useObterPedidoPorMatricula from "../../Hooks/useObterPedidoPorMatricula";
import useObterPrazoBI from "../../Hooks/useObterPrazoBI";
import useServicosComposicoesInsumos from "../../Hooks/useServicosComposicoesInsumos";
import usePedidoState from "../../Hooks/useStateCriarPedido";
import useTipoDeSolicitacao from "../../Hooks/useTipoDeSolicitacao";
import { TipoDeSolicitacao } from "../../Interface/IFormulario";
import { LoadingProps } from "../../Interface/ILoading";
import { InsumoComposicaoAtualizadoProps } from "../../Interface/ITabelaQuantitativo";
import { postCadastrar } from "../../Repository/domain/Pedidos";
import { filtrarOpcoes } from "../../Utils/FiltrarOpcoes";

const CriarPedido: React.FC<LoadingProps> = ({ setLoading }) => {
  const [valorPagar, setValorPagar] = useState<number>(0);
  const [numeroProcessoAnteriorSelecionado, setProcessoAnterior] = useState<string>("");
  const [contratoUAUSelecionado, setContratoUAU] = useState<string>("");
  const [haveraDistrato, setHaveraDistrato] = useState<string>("");
  const [fornecedorSelecionado, setFornecedorSelecionado] = useState<string>("");
  const [mostrarPrazoContratacao, setMostrarPrazoContratacao] = useState(true);

  const {
    opcoesInsumoComposicao,
    setOpcoesInsumoComposicao,
    itensSelecionadosQuantitativos,
    setItensSelecionadosQuantitativos,
    itensSelecionadosOrcamentos,
    setItensSelecionadosOrcamentos,
    dadosDaEmpresa,
    setDadosDaEmpresa,
    dadosDosServicos,
    setDadosDosServicos,
    codigoUsuario,
    solicitacao,
    setSolicitacao,
    dadosDaObra,
    setDadosDaObra,
    estado,
    setEstado,
    detalhesDoPedido,
    setdetalhesDoPedido,
    codigoServicoBI,
    setCodigoServicoBI,
    datas,
    setDatas,
    orcamentoExistente,
    setOrcamentoExistente,
    justificativa,
    setJustificativa,
    itensSelecionados,
    setItensSelecionados,
    orcamentosSelecionados,
    setOrcamentosSelecionados,
    arquivos,
    setArquivos,
  } = usePedidoState();
  const { showAlert, alertMessage, alertType, showAlertMessage } = useAlert();
  const navigate = useNavigate();

  const [, setIsFormValid] = useState(true);

  const obterEmpresaObraPorMatricula = useObterPedidoPorMatricula(
    codigoUsuario,
    setLoading
  );
  const obterPrazoBI = useObterPrazoBI(dadosDaObra.codigoObra, codigoServicoBI);

  const tipoDeSolicitacao = useTipoDeSolicitacao();
  const { servicos, composicoes, insumos } = useServicosComposicoesInsumos(opcoesInsumoComposicao);

  const handledadosDaEmpresa = (codigoEmpresa: string, nomeEmpresa: string, cnpj: string) => {
    const dadosEmpresa = { codigoEmpresa, nomeEmpresa, cnpj };
    setDadosDaEmpresa(dadosEmpresa);
  };

  const handledadosDaObra = (codigoObra: string, codigoObraFiscal: string, nomeObra: string) => {
    const dadosObra = { codigoObra, codigoObraFiscal, nomeObra };
    setDadosDaObra(dadosObra);
  };

  const handleEstadoObra = (estado: string) => {
    setEstado(estado);
  };

  const handleValorPagarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valorPagar = Number(event.target.value.replace(/[R$.,\s]/g, "")) / 100;
    setValorPagar(valorPagar);
  };

  const handleProcessoAnterior = (event: React.ChangeEvent<HTMLInputElement>) => {
    let processoAnterior = event.target.value.replace(/^0+/, "");
    setProcessoAnterior(processoAnterior);
  };

  const handleContratoUAU = (event: React.ChangeEvent<HTMLInputElement>) => {
    let contratoUAU = event.target.value.replace(/^0+/, "");
    setContratoUAU(contratoUAU);
  };

  const handleFornecedorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFornecedorSelecionado(event.target.value);
  };

  const handleDistratoChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setHaveraDistrato(event.target.value);
  };

  const handleSelectChangeQuantitativos = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;
    const selectedItem =
      opcoesInsumoComposicao === "Insumos"
        ? insumos.find((item: { descricao: string }) => item.descricao === selectedOption)
        : composicoes.find((item: { descricao: string }) => item.descricao === selectedOption);

    if (selectedItem) {
      const itemComMaterial = {
        ...selectedItem,
        material: opcoesInsumoComposicao === "Insumos",
      };

      setItensSelecionadosQuantitativos((prevState) => [...prevState, itemComMaterial]);
      event.target.value = "";
    }
  };

  const removerItemQuantitativos = (index: number) => {
    setItensSelecionadosQuantitativos((prevState) => prevState.filter((_, i) => i !== index));
  };

  const removerItemOrcamentos = (index: number) => {
    setItensSelecionadosOrcamentos((prevState) => prevState.filter((_, i) => i !== index));
  };

  const handleSelectChangeOrcamentos = (descricao: string) => {
    const selectedItem = composicoes.find(
      (item: { descricao: string }) => item.descricao === descricao
    );

    if (selectedItem) {
      setItensSelecionadosOrcamentos((prevState) => [...prevState, selectedItem]);
    }
  };

  const handleTipoDeSolicitacao = (id: number, tipoSolicitacao: string) => {
    const dadosSolicitacao: TipoDeSolicitacao = { id, tipoSolicitacao };
    setSolicitacao(dadosSolicitacao);

    if (tipoSolicitacao !== "Contratação Inicial") {
      setMostrarPrazoContratacao(false);
    } else {
      setMostrarPrazoContratacao(true);
    }
  };

  const handleCodigoServicoBISelecionado = (
    codigoBI: string,
    id: number,
    nomeServico: string
  ) => {
    const dadosServico = { codigoBI, id, nomeServico };

    setCodigoServicoBI(dadosServico.codigoBI);
    setDadosDosServicos(dadosServico);
  };

  const handleAnexosChange = (
    novosAnexos: { id: number; tipoDocumento: string; nomeArquivo: string }[]
  ) => {
    setArquivos(novosAnexos);
  };

  const validarFormulario = () => {
    const formularioValido =
      codigoUsuario && dadosDaEmpresa.codigoEmpresa && dadosDaObra.codigoObra;
    const quantitativosValidos = itensSelecionadosQuantitativos.length > 0;
    const orcamentosValidos = itensSelecionadosOrcamentos.length > 0;
    const orcamentoValido =
      !orcamentoExistente || (justificativa && justificativa.trim() !== "");
    const dataAssinatura = datas.dataDeAssinatura;
    const dataInicio = datas.prazoParaExecucaoInicial;
    const isInclusaoServico = solicitacao?.tipoSolicitacao === "Inclusão de Serviço ao Escopo Contratual";
    const dataFim = datas.prazoParaExecucaoFinal;
    const pedidoEmergencialValido =
      !datas.pedidoEmergencial || datas.justificativaSelecionada;

    const formValido =
      formularioValido &&
      quantitativosValidos &&
      orcamentosValidos &&
      orcamentoValido &&
      dataAssinatura &&
      dataInicio &&
      dataFim &&
      pedidoEmergencialValido;

    if (!formValido) {
      if (!formularioValido) {
        showAlertMessage(
          "Preencha todos os campos obrigatórios do formulário.",
          "danger"
        );
      } else if (!quantitativosValidos) {
        showAlertMessage("Verifique o campo de itens da solicitação/cotação", "danger");
      } else if (!orcamentosValidos) {
        showAlertMessage("Selecione o campo um item do orçamento.", "danger");
      } else if (!orcamentoValido) {
        showAlertMessage("Justificativa do orçamento é obrigatória.", "danger");
      } else if (!dataAssinatura) {
        showAlertMessage("Preencha o campo data necessaria para assinatura do contrato.", "danger");
      }
      else if (!dataInicio) {
        showAlertMessage("Preencha o prazo para execução do serviço (início):.", "danger")
      }
      else if (!dataFim) {
        showAlertMessage("Preencha o Prazo para execução do serviço (fim):.", "danger")
      }
      else if (!pedidoEmergencialValido) {
        showAlertMessage("Preencha a justificativa para pedido emergencial.", "danger");
      }
      return false;
    }

    if (isInclusaoServico) {
      if (!detalhesDoPedido.relacaoDeProjetos) {
        showAlertMessage(
          "Preencha a relação dos projetos a serem encaminhados aos prestadores de serviço.",
          "danger"
        );
        return false;
      }
      if (!detalhesDoPedido.especificacaoDosMateriais) {
        showAlertMessage(
          "Preencha a especificação dos materiais a serem aplicados nos serviços.",
          "danger"
        );
        return false;
      }
      if (!detalhesDoPedido.nbrs) {
        showAlertMessage("Preencha as NBRs aplicáveis.", "danger");
        return false;
      }
      if (!detalhesDoPedido.obrigacoesFornecedor) {
        showAlertMessage("Preencha as obrigações dos fornecedores.", "danger");
        return false;
      }
      if (!detalhesDoPedido.fornecedoresIndicados) {
        showAlertMessage(
          "Preencha os fornecedores indicados (nome, telefone e e-mail).",
          "danger"
        );
        return false;
      }
      if (!detalhesDoPedido.condicoesPagamento) {
        showAlertMessage("Preencha as condições de pagamento.", "danger");
        return false;
      }
      if (!detalhesDoPedido.criterioMedicao) {
        showAlertMessage("Preencha o critério de medição.", "danger");
        return false;
      }
      if (!detalhesDoPedido.detalhamentoDoServico) {
        showAlertMessage("Preencha o detalhamento do serviço.", "danger");
        return false;
      }
    }
    setIsFormValid(true);
    return true;
  };

  const handleDatas = (dadosRecebidos: {
    dataDeAssinatura: string;
    prazoParaContratacao: string;
    prazoParaExecucaoInicial: string;
    prazoParaExecucaoFinal: string;
    pedidoEmergencial: boolean;
    justificativaSelecionada: string;
  }) => {
    setDatas(dadosRecebidos);
  };

  const handleDetalhesPedidos = (detalhesPedido: {
    justificativa: string;
    relacaoDeProjetos: string;
    especificacaoDosMateriais: string;
    nbrs: string;
    obrigacoesFornecedor: string;
    fornecedoresIndicados: string;
    condicoesPagamento: string;
    criterioMedicao: string;
    detalhamentoDoServico: string;
  }) => {
    setdetalhesDoPedido(detalhesPedido);
  };

  const handleAtualizarItensOrcamentos = (
    itensAtualizados: InsumoComposicaoAtualizadoProps[]
  ) => {
    setOrcamentosSelecionados(itensAtualizados);
  };

  const handleOrcamentoChange = useCallback(
    (novoOrcamentoExistente: boolean, novaJustificativa: string) => {
      if (orcamentoExistente !== novoOrcamentoExistente) {
        setOrcamentoExistente(novoOrcamentoExistente);
      }
      if (justificativa !== novaJustificativa) {
        setJustificativa(novaJustificativa);
      }
    },
    [orcamentoExistente, justificativa, setOrcamentoExistente, setJustificativa]
  );

  const handleAtualizarItens = useCallback(
    (dadosAtualizados: InsumoComposicaoAtualizadoProps[]) => {
      setItensSelecionados(dadosAtualizados);
    },
    [setItensSelecionados]
  );

  const fecthCadastrarPedido = async () => {
    if (!validarFormulario()) {
      return;
    }
    try {
      setLoading(true);

      const formData = new FormData();

      const numeroProcessoAnterior =
        numeroProcessoAnteriorSelecionado === "" ? 0 : Number(numeroProcessoAnteriorSelecionado);
      const contratoUAU = contratoUAUSelecionado === "" ? 0 : Number(contratoUAUSelecionado);
      const dadosDoPedido = {
        pedido: {
          codigoUsuario,
          codigoPedido: 0,
          codigoEmpresa: dadosDaEmpresa.codigoEmpresa,
          razaoSocial: dadosDaEmpresa.nomeEmpresa,
          cnpj: dadosDaEmpresa.cnpj,
          estado: estado,
          codigoObra: dadosDaObra.codigoObra,
          codigoObraFiscal: dadosDaObra.codigoObraFiscal,
          nomeEmpreendimento: dadosDaObra.nomeObra,
          tipoSolicitacao: solicitacao ? solicitacao.id : 0,
          descricaoTipoSolicitacao: solicitacao ? solicitacao.tipoSolicitacao : "",
          tipoServico: dadosDosServicos.nomeServico,
          idServico: dadosDosServicos.id,
          codigoServicoBI: dadosDosServicos.codigoBI,
          descricaoServico: dadosDosServicos.nomeServico,
          tipoAditivo: null,
          descricaoAditivo: null,
          dataAssinatura: datas.dataDeAssinatura,
          prazoContratacao: datas.prazoParaContratacao,
          flagEmergencial: datas.pedidoEmergencial,
          justificativaEmergencial: datas.justificativaSelecionada,
          dataInicioExecucao: datas.prazoParaExecucaoInicial,
          dataFimExecucao: datas.prazoParaExecucaoFinal,
          relacaoProjetos: detalhesDoPedido.relacaoDeProjetos,
          especificacaoMateriais: detalhesDoPedido.especificacaoDosMateriais,
          nbrs: detalhesDoPedido.nbrs,
          obrigacoesFornecedores: detalhesDoPedido.obrigacoesFornecedor,
          fornecedoresIndicados: detalhesDoPedido.fornecedoresIndicados,
          condicoesPagamentos: detalhesDoPedido.condicoesPagamento,
          criterioMedicao: detalhesDoPedido.criterioMedicao,
          detalhamento: detalhesDoPedido.detalhamentoDoServico,
          idEmpresaObra: 0,
          justificativaReabertura: null,
          justificativaPedido: detalhesDoPedido.justificativa,
          quantitativos: itensSelecionados.map((item) => ({
            codigoComposicao: item.codigo,
            descricaoComposicao: item.descricao,
            unidade: item.unidadeComposicao,
            desvio: null,
            material: item.material,
            quantidadeSolicitada: item.quantidadeSolicitada,
            observacoes: item.observacoes,
            codigoPLM: item.codigoPLM,
            categoriaUAU: item.categoriaUAU,
          })),
          orcamentos: {
            ValorTotalOrcamento: 0,
            justificativa: justificativa,
            itemSemOrcamento: orcamentoExistente,
            orcamentoItens: orcamentosSelecionados.map((item) => ({
              codigoOrcamento: item.codigo,
              itemOrcamento: item.descricao,
              unidadeOrcada: item.unidadeOrcada,
              quantidadeOrcada: item.quantidadeOrcada,
              valorUnitario: item.valorUnitario,
              valorOrcamento: item.valorOrcamento,
              valorAjustado: item.valorAjustado,
              observacoes: item.observacoes,
              ativo: false,
            })),
          },
          processoAnterior: numeroProcessoAnterior,
          fornecedor: fornecedorSelecionado,
          contratoUAU: contratoUAU,
          distrato: haveraDistrato === "1",
          saldoMedicaoPagar: "",
          valorPagar: valorPagar,
        },
      };
      formData.append("pedido", JSON.stringify(dadosDoPedido));

      arquivos.forEach((arquivo) => {
        const nomeArquivoFormatado = `${arquivo.tipoDocumento}_${arquivo.nomeArquivo}`;
        if (arquivo.arquivo) {
          formData.append(`arquivos`, arquivo.arquivo, nomeArquivoFormatado);
        }
      });

      const response = await postCadastrar(formData);
      const { success, data } = response;
      if (success) {
        showAlertMessage(`Pedido ${data.numeroPedido} criado.`, "success");
        setTimeout(() => {
          navigate("/pedidos");
        }, 5000);
      } else {
        showAlertMessage("Erro ao criar pedido" + response.error, "warning");
      }
    } catch (error: any) {
      alert("Erro ao criar pedido" + error.message);
    } finally {
      setLoading(false);
    }
  };

  const [searchTermOrcamento, setSearchTermOrcamento] = useState("");

  const handleSearchChangeOrcamento = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTermOrcamento(e.target.value);
  };

  const filteredOrcamentoOptions = filtrarOpcoes(searchTermOrcamento, composicoes);

  const handleOptionSelectOrcamento = (descricao: string) => {
    handleSelectChangeOrcamentos(descricao);
    setSearchTermOrcamento(""); // Limpa o campo de busca após a seleção
  };

  return (
    <div className="containerfluid">
      <div className="d-flex flex-column gap-5 w-100" style={{ marginTop: "40px" }}>
        {showAlert && <AlertaBootstrap mensagem={alertMessage} tipo={alertType} />}
        <Formulario
          mode="create"
          desabilitado={false}
          empresasObras={obterEmpresaObraPorMatricula}
          tipoDeSolicitacao={tipoDeSolicitacao}
          tipoDeServicos={servicos}
          onDadosDaEmpresa={handledadosDaEmpresa}
          onDadosTipoSolicitacao={handleTipoDeSolicitacao}
          onDadosDaObra={handledadosDaObra}
          onEstadoObra={handleEstadoObra}
          onValorPagar={handleValorPagarChange}
          onProcessoAnterior={handleProcessoAnterior}
          processoAnterior={numeroProcessoAnteriorSelecionado}
          onContratoUAU={handleContratoUAU}
          contratoUAU={contratoUAUSelecionado}
          valorPagar={valorPagar}
          onFornecedorSelecionado={handleFornecedorChange}
          fornecedorSelecionado={fornecedorSelecionado}
          onHaveraDistrato={handleDistratoChange}
          haveraDistrato={haveraDistrato}
          onTipoServicoSelecionado={handleCodigoServicoBISelecionado}
          mostrarPrazoContratacao={mostrarPrazoContratacao}
        />
        <hr />
        <OpcaoInsumoComposicao
          opcoesInsumoComposicao={opcoesInsumoComposicao}
          setOpcoesInsumoComposicao={setOpcoesInsumoComposicao}
          insumos={insumos}
          composicoes={composicoes}
          handleSelectChangeQuantitativos={handleSelectChangeQuantitativos}
        />
        <TabelaQuantitativos
          mode="create"
          desabilitado={false}
          itensSelecionados={itensSelecionadosQuantitativos}
          empresa={dadosDaEmpresa.codigoEmpresa}
          obra={dadosDaObra.codigoObra}
          onRemoverItem={removerItemQuantitativos}
          onAtualizarItens={handleAtualizarItens}
        />
        <div className="form-floating">
          <BuscarCampo
            searchTerm={searchTermOrcamento}
            onSearchChange={handleSearchChangeOrcamento}
            onOptionSelect={handleOptionSelectOrcamento}
            filteredOptions={filteredOrcamentoOptions}
            placeholder="Código do item do orçamento..."
          />
        </div>
        <TabelaOrcamentos
          mode="create"
          desabilitado={false}
          itensSelecionados={itensSelecionadosOrcamentos}
          onRemoverItem={removerItemOrcamentos}
          onAtualizarItens={handleAtualizarItensOrcamentos}
        />
        <hr />
        <ItemSemOrcamento
          mode="create"
          desabilitado={false}
          onOrcamentoChange={handleOrcamentoChange}
        />
        <hr />
        <DataAssinatura
          mode="create"
          onDatas={handleDatas}
          desabilitado={false}
          prazoBI={obterPrazoBI}
          tipoSolicitacao={solicitacao?.tipoSolicitacao || ""}
        />
        <hr />
        <DetalhesPedido
          mode="create"
          desabilitado={false}
          onDadosDetalhesPedido={handleDetalhesPedidos}
        />
        <hr />
        <Anexos mode={"create"} onAnexosChange={handleAnexosChange} setAnexoAtualizado={undefined} />
        <hr />
        <div className="d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-outline-primary w-25"
            onClick={fecthCadastrarPedido}
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CriarPedido;
