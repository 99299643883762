import React, { useState } from "react";
import useAlert from "../../Hooks/useAlert";
import usePedidoState from "../../Hooks/useStateCriarPedido";
import { FiltrarPedido } from "../../Interface/IFiltrarPedido";
import { TipoDeServicos, TipoDeSolicitacao } from "../../Interface/IFormulario";
import { LoadingProps } from "../../Interface/ILoading";
import { postFiltrar } from "../../Repository/domain/Pedidos";
import {
 estados,
 etapaSolicitacao,
 responsavelPelaEtapa,
 statusPedido,
} from "../../Utils/Filtros/index";

interface FiltroDeBuscaProps extends LoadingProps {
 onFiltrarPedidos: (dadosFiltrados: any) => void;
 tipoDeSolicitacao?: TipoDeSolicitacao[]
 tipoDeServicos?: TipoDeServicos[]
}

const FiltroDeBusca: React.FC<FiltroDeBuscaProps> = ({
 setLoading,
 onFiltrarPedidos,
 tipoDeSolicitacao,
 tipoDeServicos
}) => {
 const { codigoUsuario } = usePedidoState();
 const [codigoObra, setCodigoObra] = useState<string>("");
 const [numeroPedido, setNumeroPedido] = useState<number>(0);
 const [codigoEmpresa, setCodigoEmpresa] = useState<string>("");
 const [dataPedido, setDataPedido] = useState<string>("");
 const [dataAssinaturaContrato, setDataAssinaturaContrato] =
  useState<string>("");
 const [emergencial, setEmergencial] = useState<boolean | null>(null);
 const [estado, setEstado] = useState<string>("");
 const [etapa, setEtapa] = useState<number>(0);
 const [idEmpresaObra] = useState<number>(0);
 const [responsavelEtapa, setResponsavelEtapa] = useState<string>("");
 const [status, setStatus] = useState<number>(0);
 const [tipoAditivo] = useState<number>(0);
 const [tipoServico, setTipoServico] = useState<string>("");
 const [tipoSolicitacao, setTipoSolicitacao] = useState<string>("");
 const { showAlertMessage } = useAlert();

 const handleNumeroPedido = (event: React.ChangeEvent<HTMLInputElement>) => {
  const numeroAtualizado = parseInt(event.target.value);
  setNumeroPedido(numeroAtualizado);
 };

 const handleCodigoObra = (event: React.ChangeEvent<HTMLInputElement>) => {
  const obraAtualizada = event.target.value;
  setCodigoObra(obraAtualizada);
 };

 const handleCodigoEmpresa = (event: React.ChangeEvent<HTMLInputElement>) => {
  const empresaAtualizada = event.target.value;
  setCodigoEmpresa(empresaAtualizada);
 };

 const handleAtualizacaoEstados = (
  event: React.ChangeEvent<HTMLSelectElement>
 ) => {
  const UF_Atualizada = event.target.value;
  setEstado(UF_Atualizada);
 };

 const handleAtualizacaoStatus = (
  event: React.ChangeEvent<HTMLSelectElement>
 ) => {
  const StatusAtualizado = parseInt(event.target.value);
  setStatus(StatusAtualizado);
 };

 const handleAtualizacaoEtapa = (
  event: React.ChangeEvent<HTMLSelectElement>
 ) => {
  const EtapaAtualizada = parseInt(event.target.value);
  setEtapa(EtapaAtualizada);
 };

 const handleAtualizacaoEmergencial = (
  event: React.ChangeEvent<HTMLSelectElement>
 ) => {
  const value = event.target.value;
  if (value === "true") {
   setEmergencial(true);
  } else if (value === "false") {
   setEmergencial(false);
  } else {
   setEmergencial(null);
  }
 };

 const handleAtualizacaoResponsavelEtapa = (
  event: React.ChangeEvent<HTMLSelectElement>
 ) => {
  const responsavelEtapaAtualizado = event.target.value;
  setResponsavelEtapa(responsavelEtapaAtualizado);
 };

 const setNullIfEmpty = (value: any) => {
  return value !== undefined && value !== "" && value !== 0 ? value : null;
 };

 const handleFiltrarClick = async () => {
  const formData: FiltrarPedido = {
   codigoUsuario: codigoUsuario,
   numeroPedido: setNullIfEmpty(numeroPedido),
   codigoObra: setNullIfEmpty(codigoObra),
   codigoEmpresa: setNullIfEmpty(codigoEmpresa),
   status: setNullIfEmpty(status),
   dataPedido: setNullIfEmpty(dataPedido),
   idEmpresaObra: setNullIfEmpty(idEmpresaObra),
   tipoSolicitacao: setNullIfEmpty(tipoSolicitacao),
   tipoServico: setNullIfEmpty(tipoServico),
   tipoAditivo: setNullIfEmpty(tipoAditivo),
   estado: setNullIfEmpty(estado),
   emergencial: setNullIfEmpty(emergencial),
   empresa: null,
   dataAssinaturaContrato: setNullIfEmpty(dataAssinaturaContrato),
   responsavelEtapa: setNullIfEmpty(responsavelEtapa),
   etapa: setNullIfEmpty(etapa),
  };

  try {
   setLoading(true);
   const res = await postFiltrar(formData);
   onFiltrarPedidos(res);
  } catch (error) {
   showAlertMessage("Erro ao filtrar dados:" + error, "warning");
  } finally {
   setLoading(false);
  }
 };

 const handleLimparCampos = () => {
  setCodigoObra("");
  setNumeroPedido(0);
  setCodigoEmpresa("");
  setDataPedido("");
  setTipoSolicitacao("");
  setTipoServico("");
  setDataAssinaturaContrato("");
  setEmergencial(null);
  setEstado("");
  setEtapa(0);
  setResponsavelEtapa("");
  setStatus(0);
 };

 return (
  <div className="d-flex flex-column gap-3" style={{ fontSize: "15px" }}>
   <div className="d-flex gap-3">
    <div className="form-floating w-25 mb-3">
     <input
      type="number"
      className="form-control"
      id="NumeroSolicitacao"
      value={numeroPedido}
      onChange={handleNumeroPedido}
     />
     <label htmlFor="NumeroSolicitacao">Número da solicitação</label>
    </div>
    <div className="form-floating w-25 mb-3">
     <input
      type="text"
      className="form-control"
      id="Obra"
      value={codigoObra}
      onChange={handleCodigoObra}
     />
     <label htmlFor="Obra">Obra</label>
    </div>
    <div className="form-floating w-25 mb-3">
     <input
      type="text"
      className="form-control"
      id="Empresa"
      value={codigoEmpresa}
      onChange={handleCodigoEmpresa}
     />
     <label htmlFor="Empresa">Empresa</label>
    </div>
    <div className="form-floating w-25">
     <select
      className="form-select"
      id="Estado"
      aria-label="seletor Estado "
      value={estado}
      onChange={handleAtualizacaoEstados}
     >
      <option value="">Selecione...</option>
      {Object.keys(estados).map((sigla) => (
       <option key={sigla} value={sigla}>
        {estados[sigla]}
       </option>
      ))}
     </select>
     <label htmlFor="Estado">Estado</label>
    </div>
   </div>
   <div className="d-flex gap-3">
    <div className="form-floating w-25 mb-3">
     <input
      type="date"
      className="form-control"
      id="DataSolicitacao"
      value={dataPedido}
      onChange={(e) => setDataPedido(e.target.value)}
     />
     <label htmlFor="DataSolicitacao">Data da solicitacao</label>
    </div>
    <div className="form-floating w-25">
     <select
      className="form-select"
      id="tipoSolicitacao"
      aria-label="seletor tipo de solicitacao "
      value={tipoSolicitacao}
      onChange={(e) => setTipoSolicitacao(e.target.value)}
     >
      <option value="">Selecione...</option>
      {tipoDeSolicitacao && tipoDeSolicitacao.map((solicitacao: any) => (
       <option
        key={solicitacao.id}
        value={solicitacao.tipoSolicitacao}
       >
        {solicitacao.tipoSolicitacao}
       </option>
      ))}
     </select>
     <label htmlFor="tipoSolicitacao">Tipo de solicitação</label>
    </div>
    <div className="form-floating w-25">
     <select
      className="form-select"
      id="tipoServico"
      aria-label="seletor tipo de servico"
      value={tipoServico}
      onChange={(e) => setTipoServico(e.target.value)}
      style={{

      }}
     >
      <option value="">Selecione...</option>
      {tipoDeServicos && tipoDeServicos
       .slice()
       .sort((a, b) => a.nomeServico.localeCompare(b.nomeServico))
       .map((servico) => (
        <option key={servico.id} value={servico.nomeServico}>
         {servico.nomeServico.length >= 90 ? servico.nomeServico.substring(0, 90) : servico.nomeServico}
        </option>
       ))}
     </select>
     <label htmlFor="tipoServico">Tipo de serviço</label>
    </div>
    <div className="form-floating w-25">
     <input
      type="date"
      className="form-control"
      id="DataAssinaturaContrato"
      value={dataAssinaturaContrato}
      onChange={(e) => setDataAssinaturaContrato(e.target.value)}
     />
     <label htmlFor="DataAssinaturaContrato">
      Data assinatura contrato
     </label>
    </div>
   </div>
   <div className="d-flex gap-3">
    <div className="form-floating w-25">
     <select
      className="form-select"
      id="Status"
      aria-label="seletor status "
      value={status}
      onChange={handleAtualizacaoStatus}
     >
      <option value="">Selecione...</option>
      {Object.keys(statusPedido).map((status) => (
       <option key={status} value={status}>
        {statusPedido[status]}
       </option>
      ))}
     </select>
     <label htmlFor="Status">Status</label>
    </div>
    <div className="form-floating w-25">
     <select
      className="form-select"
      id="Etapa"
      aria-label="seletor etapa "
      value={etapa}
      onChange={handleAtualizacaoEtapa}
     >
      <option value="">Selecione...</option>
      {Object.keys(etapaSolicitacao).map((etapa) => (
       <option key={etapa} value={etapa}>
        {etapaSolicitacao[etapa]}
       </option>
      ))}
     </select>
     <label htmlFor="Etapa">Etapa</label>
    </div>
    <div className="form-floating w-25">
     <select
      className="form-select"
      id="Emergencial"
      aria-label="seletor emergencial "
      value={emergencial?.toString() || ""}
      onChange={handleAtualizacaoEmergencial}
     >
      <option value="">Selecione...</option>
      <option value="true">Sim</option>
      <option value="false">Não</option>
     </select>
     <label htmlFor="Emergencial">Emergencial</label>
    </div>
    <div className="form-floating w-25">
     <select
      className="form-select"
      id="ResponsavelEtapa"
      aria-label="seletor responsavel etapa "
      value={responsavelEtapa}
      onChange={handleAtualizacaoResponsavelEtapa}
     >
      <option value={""}>Selecione...</option>
      {Object.keys(responsavelPelaEtapa).map((responsavel) => (
       <option key={responsavel} value={responsavel}>
        {responsavelPelaEtapa[responsavel]}
       </option>
      ))}
     </select>
     <label htmlFor="ResponsavelEtapa">Responsável pela etapa</label>
    </div>
   </div>
   <div className="d-flex gap-3 justify-content-end">
    <button
     className="btn btn-outline-primary"
     onClick={handleFiltrarClick}
    >
     Buscar
    </button>
    <button className="btn btn-outline-danger" onClick={handleLimparCampos}>
     Limpar
    </button>
   </div>
  </div>
 );
};

export default FiltroDeBusca;
