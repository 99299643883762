export const filtrarOpcoes = (
 searchTerm: string,
 items: { codigo: string; descricao: string }[]
) => {
 if (searchTerm.length < 3) return [];
 return items.filter((item) =>
  `${item.codigo} - ${item.descricao}`
   .toLowerCase()
   .includes(searchTerm.toLowerCase())
 );
};