import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  useExibcaoCamposAprovacao,
  useFinalizacaoPedidoSup3,
  usePermissao,
  usePermissaoParaFinalizacao,
  usePermissaoSuprimentos,
  usePermissaoSuprimentos4,
} from "../../Hooks/usePermissao";
import { AtualizarPedidoProps } from "../../Interface/IAtualizarPedido";
import {
  FinalizarPedido,
  VerificaDivergenciaContratual,
} from "../../Interface/IFinalizarPedido";
import client from "../../Repository/config/client";
import {
  postCancelar,
  postFinalizar,
  postVerificaDivergenciaContratual,
  putAtualizarPedido,
} from "../../Repository/domain/Pedidos";
import { handleApiError } from "../../Utils/ErrorHandle";
import ModalConfirmacao from "../ModalConfirmacao";

interface ParecerAprovacaoParametros {
  idPedido: number;
  contrato: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const ParecerAprovacao: React.FC<ParecerAprovacaoParametros> = ({
  idPedido,
  contrato,
  setLoading,
}: ParecerAprovacaoParametros) => {
  const navigate = useNavigate();
  const temPermissao = usePermissao();
  const exibirCampoAprovacao = useExibcaoCamposAprovacao();
  const permitirFinalizacao = usePermissaoParaFinalizacao();
  const temPermissaoSuprimentos = usePermissaoSuprimentos();
  const temPermissaoSuprimentos4 = usePermissaoSuprimentos4();
  const finalizarPedidoSup3 = useFinalizacaoPedidoSup3();
  const [justificativaParecer, setJustificativaParecer] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [contratoUAU, setContratoUAU] = useState<string>("");

  useEffect(() => {
    if (finalizarPedidoSup3) {
      setContratoUAU(contrato);
    }
  }, [finalizarPedidoSup3, contrato]);

  const handleParecerChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setJustificativaParecer(event.target.value);
  };

  const handleContratoUAUChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setContratoUAU(event.target.value);
  };

  const handleCancelarPedido = () => {
    if (justificativaParecer.trim() === "") {
      alert(
        "Por favor, é necessário fornecer uma justificativa para o cancelamento do pedido."
      );
      return;
    }
    setShowModal(true);
  };
  const dadosOrcamento = JSON.parse(localStorage.getItem('dadosOrcamento') || '[]');
  const dadosQuantitativos = JSON.parse(localStorage.getItem('dadosQuantitativos') || '[]');

  const handleAtualizarPedido = async () => {
    const atualizarPedidoProps: AtualizarPedidoProps = {
      codigoPedido: idPedido,
      orcamentos: {
        orcamentoItens: dadosOrcamento.map((orcamento: {
          observacoes: any; codigo: any; descricao: any; unidadeOrcada: any; quantidadeOrcada: any; valorUnitario: string; valorOrcamento: any; valorAjustado: any;
        }) => ({
          codigoOrcamento: orcamento.codigo,
          itemOrcamento: orcamento.descricao,
          unidadeOrcada: orcamento.unidadeOrcada,
          quantidadeOrcada: Number(orcamento.quantidadeOrcada),
          valorUnitario: (typeof orcamento.valorUnitario === 'number')
            ? orcamento.valorUnitario
            : Number(orcamento.valorUnitario.replace(/[R$ ]/g, '').replace(',', '.')),
          valorOrcamento: Number(orcamento.valorOrcamento),
          valorAjustado: Number(orcamento.valorAjustado),
          observacoes: orcamento.observacoes
        })),
      },
      quantitativos: dadosQuantitativos.map((quantitativo: {
        observacoes: any; codigo: any; descricao: any; unidadeComposicao: any; quantidadeSolicitada: any; codigoPLM: any; categoriaUAU: any;
      }) => ({
        codigoComposicao: quantitativo.codigo,
        descricaoComposicao: quantitativo.descricao,
        unidade: quantitativo.unidadeComposicao,
        quantidadeSolicitada: Number(quantitativo.quantidadeSolicitada),
        codigoPLM: quantitativo.codigoPLM,
        categoriaUau: quantitativo.categoriaUAU,
        observacoes: quantitativo.observacoes
      }))
    };
    await putAtualizarPedido(atualizarPedidoProps);
    localStorage.removeItem('dadosOrcamento');
    localStorage.removeItem('dadosQuantitativos');
  }

  async function handleMovimentarPedido(
    aprovar: boolean,
    duvida: boolean
  ): Promise<void> {
    const decisao = aprovar ? "Aprovar" : "Reprovar";

    if (justificativaParecer.trim() === "") {
      alert(
        `Por favor, é necessário fornecer uma justificativa para ${decisao} o pedido.`
      );
      return;
    }
    if ((dadosOrcamento && dadosOrcamento.length > 0) || (dadosQuantitativos && dadosQuantitativos.length > 0)) {
      handleAtualizarPedido();
    };
    const headers = {
      "Content-Type": "application/json",
    };

    const payload = JSON.stringify({
      numeroPedido: idPedido,
      aprovado: aprovar,
      justificativa: justificativaParecer,
      duvida: duvida,
    });

    try {
      setLoading(true);
      const response = await client.post("Pedido/movimentar", payload, headers);

      if (response) {
        setLoading(false);
        return navigate("/pedidos");
      } else {
        alert(`Não foi possível ${decisao} pedido!`);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      alert(
        `Não foi possível ${decisao} o pedido! Erro: ${handleApiError(error)}`
      );
    }
  }

  const verificaDivergenciaContratual = async () => {
    const payload: VerificaDivergenciaContratual = {
      numeroPedido: idPedido,
      contratoUAU: parseInt(contratoUAU, 10),
    };
    try {
      setLoading(true);
      const response = await postVerificaDivergenciaContratual(payload);
      return !!response;
    } catch (error) {
      alert(
        `Contrato UAU não encontrado. Verifique o número e tente novamente. Erro: " ${handleApiError(
          error
        )}`
      );
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleFinalizarPedido = async () => {
    try {
      setLoading(true);
      const payload: FinalizarPedido = {
        numeroPedido: idPedido,
        contratoUAU: parseInt(contratoUAU, 10),
        justificativa: justificativaParecer,
      };

      const response = await postFinalizar(payload);

      if (response) {
        alert(`Pedido ${idPedido} finalizado.`);
        setTimeout(() => {
          navigate("/pedidos");
        }, 2500);
      } else {
        alert("Erro ao finalizar o pedido" + response);
      }
    } catch (error) {
      alert(
        `Não foi possível finalizar o pedido. Erro: " ${handleApiError(error)}`
      );
    } finally {
      setLoading(false);
    }
  };

  const confirmarCancelamento = async () => {
    try {
      setLoading(true);
      const payload = {
        numeroPedido: idPedido,
        justificativa: justificativaParecer,
      };
      const response = await postCancelar(payload);
      if (response) {
        return navigate("/pedidos");
      } else {
        alert(`Não foi possível cancelar o pedido ${idPedido}`);
      }
    } catch (error) {
      alert(
        `Não foi possível cancelar o pedido. Erro: " ${handleApiError(error)}`
      );
    } finally {
      setShowModal(false);
      setLoading(false);
    }
  };

  const isParecerValido = justificativaParecer.trim() !== "";
  const isFinalizarValido = contratoUAU.trim() !== "" && !!idPedido;

  const handleAprovarClick = async () => {
    if (finalizarPedidoSup3) {
      await handleFinalizarPedido();
    } else if (permitirFinalizacao) {
      if (contratoUAU.trim() === "") {
        alert("Por favor, forneça a justificativa e o número do contrato UAU.");
        return;
      }

      try {
        const hasDivergencia = await verificaDivergenciaContratual();
        if (hasDivergencia) {
          alert(
            "Divergência encontrada entre dados do  contrato UAU e inseridos no sistema. Pedido encaminhado para etapa de justificativa."
          );
          if (justificativaParecer.trim() === "") {
            alert(
              "Foi identificado uma divergência entre os sistemas SCT e UAU, que pode se referir ao fornecedor ou ao valor informado. Sendo assim, a justificativa será encaminhada para que seja aprovado pela gerência."
            );
            return;
          } else {
            await handleFinalizarPedido();
          }
        } else {
          await handleFinalizarPedido();
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      await handleMovimentarPedido(true, false);
    }
  };

  return (
    <>
      {exibirCampoAprovacao && (
        <>
          <h5>
            {permitirFinalizacao
              ? "Justificativa"
              : "Parecer da aprovação ou reprovação"}
          </h5>
          {permitirFinalizacao && (
            <div className="form-floating w-25 mb-3">
              <input
                type="number"
                className="form-control"
                id="ContratoUAU"
                value={contratoUAU}
                onChange={handleContratoUAUChange}
              />
              <label
                htmlFor="ContratoUAU"
                className="lh-1 fs-6 text-primary text-wrap"
              >
                Número contrato UAU <span className="text-danger">*</span>
              </label>
            </div>
          )}
          <div className="form-floating mt-3">
            <textarea
              className="form-control w-100 h-100"
              id="justificativaParecerAprovacao"
              value={justificativaParecer}
              onChange={handleParecerChange}
            ></textarea>
            <label
              className="lh-1 fs-6 text-primary text-wrap"
              htmlFor="justificativaParecerAprovacao"
            >
              {permitirFinalizacao
                ? "Justificativa"
                : "Parecer Aprovar/Reprovar"}
              <span className="text-danger">*</span>
            </label>
          </div>
          <div className="d-flex justify-content-between">
            {temPermissao ? (
              <>
                <div className="d-flex gap-4">
                  <button
                    className="btn btn-outline-success"
                    disabled={!isParecerValido}
                    onClick={handleAprovarClick}
                  >
                    Submeter MDE
                  </button>
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => handleMovimentarPedido(false, false)}
                    disabled={!isParecerValido}
                  >
                    Reprovar
                  </button>
                </div>
              </>
            ) : (
              <div className="d-flex gap-4">
                {!permitirFinalizacao && (
                  <button
                    className="btn btn-outline-success"
                    onClick={handleAprovarClick}
                    disabled={!isParecerValido}
                  >
                    Aprovar
                  </button>
                )}
                {permitirFinalizacao && (
                  <button
                    className="btn btn-outline-success"
                    disabled={!isFinalizarValido}
                    onClick={handleAprovarClick}
                  >
                    Finalizar pedido
                  </button>
                )}
                {temPermissaoSuprimentos4 && (
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => handleMovimentarPedido(false, true)}
                    disabled={!isParecerValido}
                  >
                    Esclarecer dúvida
                  </button>
                )}
                <button
                  className="btn btn-outline-danger"
                  onClick={() => handleMovimentarPedido(false, false)}
                  disabled={!isParecerValido}
                >
                  Reprovar
                </button>
              </div>
            )}
          </div>
        </>
      )}
      {temPermissaoSuprimentos && (
        <div className="m-3 d-flex justify-content-end">
          <button
            className="btn btn-outline-warning"
            onClick={handleCancelarPedido}
          >
            Cancelar
          </button>
        </div>
      )}

      <ModalConfirmacao
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={confirmarCancelamento}
      />
    </>
  );
};

export default ParecerAprovacao;
