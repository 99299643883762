import { useCallback, useEffect, useRef, useState } from "react";
import RoutesComponent from "./Routes";
import Spinner from "./components/Spinner";
import useLogin from "./Hooks/useLogin";

function App() {
  const [loading, setLoading] = useState(false);
  const { logado } = useLogin();
  const [menu, setMenu] = useState("");
  const [header, setHeader] = useState("");
  const [iframeStyle, setIframeStyle] = useState({
    position: "absolute",
    top: "4rem",
    width: "",
    height: "",
  });

  const menuIframeRef = useRef(null);

  const handleMessage = useCallback(
    (event: { data: { menuAberto: any } }) => {
      const { menuAberto } = event.data;
      if (menuAberto) {
        setIframeStyle({ ...iframeStyle, height: "90px", width: "14rem" });
      } else {
        setIframeStyle({ ...iframeStyle, height: "36px", width: "auto" });
      }
    },
    [iframeStyle]
  );

  const montarRotasIframesSistema = () => {
    const tokenCognito = localStorage.getItem("tokenCognito");
    const tokenAcesso = localStorage.getItem("accessToken");

    if (tokenCognito && tokenAcesso) {
      setMenu(
        `${process.env.REACT_APP_URL_DIRIOBRAS}/login/sct/menu/${tokenCognito}/${tokenAcesso}`
      );
      setHeader(
        `${process.env.REACT_APP_URL_DIRIOBRAS}/login/sct/header/${tokenCognito}/${tokenAcesso}`
      );
    } else {
      setMenu("");
      setHeader("");
    }
  };

  useEffect(() => {
    montarRotasIframesSistema();
  }, [logado, handleMessage]);

  return (
    <>
      {header && (
        <iframe
          title="header"
          src={header}
          frameBorder="0"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "4rem",
          }}
        />
      )}

      {menu && (
        <iframe
          title="menu"
          src={menu}
          frameBorder="0"
          ref={menuIframeRef}
          style={{
            ...iframeStyle,
            position: "absolute",
            top: "4rem",
            left: 0,
            zIndex: 2,
            width: "100%",
            height: "3rem"
          }}
        />
      )}

      {loading && <Spinner />}
      <main className="gap-3 d-flex mt-4 m-5" style={{ width: "90%" }}>
        <RoutesComponent setLoading={setLoading} />
      </main>
    </>
  );
}

export default App;
