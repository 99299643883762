import { FC } from "react";

interface inputProps{
    obras: {nome: string, codigo: string}[],
    onClick: (obra: {nome: string, codigo: string}) => void
}

export const OptionsInput: FC<inputProps> = (props) => {
    const { obras, onClick } = props;

    return (
        <>
            <ul 
                className="list-group mt-1 w-100" 
                style={{ position: 'absolute', zIndex: 1000, maxHeight: 300, overflowY: 'auto' }}
            >
                {obras.map((obra, index) => (
                    <li
                        key={index}
                        className="list-group-item list-group-item-action"
                        onClick={() => onClick(obra)}
                    >
                    {obra.nome}
                    </li>
                ))}
            </ul>
        </>
    );
}