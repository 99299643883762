import React, { useEffect, useState, useCallback } from "react";
import {
  DetalhesPedidoProps,
  CriarDetalhesPedidoProps,
  VisualizarDetalhesPedidoProps,
} from "../../Interface/IDetalhePedido";

const DetalhesPedido: React.FC<DetalhesPedidoProps> = (props) => {
  const { mode } = props;
  const isCreateMode = mode === "create";

  const createProps = isCreateMode ? (props as CriarDetalhesPedidoProps) : null;
  const visualizarProps = !isCreateMode
    ? (props as VisualizarDetalhesPedidoProps)
    : null;

  const [justificativa, setJustificativa] = useState<string>("");
  const [relacaoDeProjetos, setRelacaoDeProjetos] = useState<string>("");
  const [especificacaoDosMateriais, setEspecificacaoDosMateriais] =
    useState<string>("");
  const [nbrs, setNbrs] = useState<string>("");
  const [obrigacoesFornecedor, setObrigacoesFornecedor] = useState<string>("");
  const [fornecedoresIndicados, setFornecedoresIndicados] =
    useState<string>("");
  const [condicoesPagamento, setCondicoesPagamento] = useState<string>("");
  const [criterioMedicao, setCriterioMedicao] = useState<string>("");
  const [detalhamentoDoServico, setDetalhamentoDoServico] =
    useState<string>("");

  const handleDadosDetalhesPedido = useCallback(() => {
    if (createProps && createProps.onDadosDetalhesPedido) {
      const data = {
        justificativa,
        relacaoDeProjetos,
        especificacaoDosMateriais,
        nbrs,
        obrigacoesFornecedor,
        fornecedoresIndicados,
        condicoesPagamento,
        criterioMedicao,
        detalhamentoDoServico,
      };
      createProps.onDadosDetalhesPedido(data);
    }
  }, [
    justificativa,
    relacaoDeProjetos,
    especificacaoDosMateriais,
    nbrs,
    obrigacoesFornecedor,
    fornecedoresIndicados,
    condicoesPagamento,
    criterioMedicao,
    detalhamentoDoServico,
    // Propriedade createProps removido por gerar loop infinito
  ]);

  useEffect(() => {
    handleDadosDetalhesPedido();
  }, [
    justificativa,
    relacaoDeProjetos,
    especificacaoDosMateriais,
    nbrs,
    obrigacoesFornecedor,
    fornecedoresIndicados,
    condicoesPagamento,
    criterioMedicao,
    detalhamentoDoServico,
    handleDadosDetalhesPedido,
  ]);

  if (isCreateMode && createProps) {
    const { desabilitado } = createProps;

    return (
      <div className="container-fluid d-flex flex-column w-100">
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="justificativa"
            disabled={desabilitado}
            value={justificativa}
            onChange={(e) => setJustificativa(e.target.value)}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="justificativa"
          >
            Justificativa<span className="text-danger">*</span>
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="relacaoDeProjetos"
            disabled={desabilitado}
            value={relacaoDeProjetos}
            onChange={(e) => setRelacaoDeProjetos(e.target.value)}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="relacaoDeProjetos"
          >
            Relação de projetos a serem encaminhados aos prestadores de serviços
            <span className="text-danger">*</span>
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="especificacaoDosMateriais"
            disabled={desabilitado}
            value={especificacaoDosMateriais}
            onChange={(e) => setEspecificacaoDosMateriais(e.target.value)}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="especificacaoDosMateriais"
          >
            Especificação dos materiais a serem aplicados nos serviços
            <span className="text-danger">*</span>
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="NBRS"
            disabled={desabilitado}
            value={nbrs}
            onChange={(e) => setNbrs(e.target.value)}
          ></textarea>
          <label className="lh-1 fs-6 text-primary text-wrap" htmlFor="NBRS">
            NBR's<span className="text-danger">*</span>
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="obrigacoesFornecedor"
            disabled={desabilitado}
            value={obrigacoesFornecedor}
            onChange={(e) => setObrigacoesFornecedor(e.target.value)}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="obrigacoesFornecedor"
          >
            Obrigações do fornecedor<span className="text-danger">*</span>
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="fornecedoresIndicados"
            disabled={desabilitado}
            value={fornecedoresIndicados}
            onChange={(e) => setFornecedoresIndicados(e.target.value)}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="fornecedoresIndicados"
          >
            Fornecedores indicados (nome, telefone e e-mail)
            <span className="text-danger">*</span>
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="condicaoDePagamento"
            disabled={desabilitado}
            value={condicoesPagamento}
            onChange={(e) => setCondicoesPagamento(e.target.value)}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="condicaoDePagamento"
          >
            Condição de pagamento<span className="text-danger">*</span>
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="criterioMedicao"
            disabled={desabilitado}
            value={criterioMedicao}
            onChange={(e) => setCriterioMedicao(e.target.value)}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="criterioMedicao"
          >
            Critério de medição<span className="text-danger">*</span>
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="detalhamentoDoServico"
            disabled={desabilitado}
            value={detalhamentoDoServico}
            onChange={(e) => setDetalhamentoDoServico(e.target.value)}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="detalhamentoDoServico"
          >
            Detalhamento do serviço<span className="text-danger">*</span>
          </label>
        </div>
      </div>
    );
  } else if (!isCreateMode && visualizarProps) {
    const { dadosDetalhesDoPedido } = visualizarProps;
    return (
      <div className="container-fluid d-flex flex-column w-100">
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="justificativa"
            disabled
            value={dadosDetalhesDoPedido.justificativaPedido}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="justificativa"
          >
            Justificativa<span className="text-danger">*</span>
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="relacaoDeProjetos"
            disabled
            value={dadosDetalhesDoPedido.relacaoProjetos}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="relacaoDeProjetos"
          >
            Relação de projetos a serem encaminhados aos prestadores de serviços
            <span className="text-danger">*</span>
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="especificacaoDosMateriais"
            disabled
            value={dadosDetalhesDoPedido.especificacaoMateriais}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="especificacaoDosMateriais"
          >
            Especificação dos materiais a serem aplicados nos serviços
            <span className="text-danger">*</span>
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="NBRS"
            disabled
            value={dadosDetalhesDoPedido.nbrs}
          ></textarea>
          <label className="lh-1 fs-6 text-primary text-wrap" htmlFor="NBRS">
            NBR's
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="obrigacoesFornecedor"
            disabled
            value={dadosDetalhesDoPedido.obrigacoesFornecedores}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="obrigacoesFornecedor"
          >
            Obrigações do fornecedor
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="fornecedoresIndicados"
            disabled
            value={dadosDetalhesDoPedido.fornecedoresIndicados}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="fornecedoresIndicados"
          >
            Fornecedores indicados (nome, telefone e e-mail)
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="condicaoDePagamento"
            disabled
            value={dadosDetalhesDoPedido.condicoesPagamentos}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="condicaoDePagamento"
          >
            Condição de pagamento
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="criterioMedicao"
            disabled
            value={dadosDetalhesDoPedido.criterioMedicao}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="criterioMedicao"
          >
            Critério de medição
          </label>
        </div>
        <div className="form-floating mt-3">
          <textarea
            className="form-control w-100 h-100"
            id="detalhamentoDoServico"
            disabled
            value={dadosDetalhesDoPedido.detalhamento}
          ></textarea>
          <label
            className="lh-1 fs-6 text-primary text-wrap"
            htmlFor="detalhamentoDoServico"
          >
            Detalhamento do serviço
          </label>
        </div>
      </div>
    );
  }
  return null;
};

export default DetalhesPedido;
