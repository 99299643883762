import React, { useCallback, useEffect, useState } from "react";
import FiltroDeBusca from "../../components/Filtro";
import TabelaPaginada from "../../components/TabelaPaginada";
import useAlert from "../../Hooks/useAlert";
import useServicosComposicoesInsumos from "../../Hooks/useServicosComposicoesInsumos";
import usePedidoState from "../../Hooks/useStateCriarPedido";
import useTipoDeSolicitacao from "../../Hooks/useTipoDeSolicitacao";
import { DadosTabela } from "../../Interface/IDadosTabela";
import { LoadingProps } from "../../Interface/ILoading";
import { getObterPedidosPorMatricula } from "../../Repository/domain/Pedidos";

const AcompanharPedido: React.FC<LoadingProps> = ({ setLoading }) => {
 const { codigoUsuario } = usePedidoState();
 const { showAlertMessage } = useAlert();
 const tipoDeSolicitacao = useTipoDeSolicitacao();
 const { servicos } = useServicosComposicoesInsumos();
 const [dadosDoPedido, setDadosDoPedido] = useState<DadosTabela[]>([]);

 const fetchObterPedidosPorMatricula = useCallback(async () => {
  try {
   setLoading(true);
   const pedidos = await getObterPedidosPorMatricula(codigoUsuario);
   setDadosDoPedido(pedidos as DadosTabela[]);
  } catch (error) {
   showAlertMessage(" " + error, "warning");
  } finally {
   setLoading(false);
  }
 }, [codigoUsuario, setLoading, showAlertMessage]);

 useEffect(() => {
  fetchObterPedidosPorMatricula();
 }, [fetchObterPedidosPorMatricula]);

 const handleFiltrarPedidos = (dadosFiltrados: DadosTabela[]) => {
  setDadosDoPedido(dadosFiltrados);
 };

 return (
  <div
   className="d-flex flex-column gap-5 w-100"
   style={{ marginTop: "40px" }}
  >
   <FiltroDeBusca
    tipoDeSolicitacao={tipoDeSolicitacao}
    tipoDeServicos={servicos}
    setLoading={setLoading}
    onFiltrarPedidos={handleFiltrarPedidos}
   />
   <TabelaPaginada dados={dadosDoPedido} itensPorPagina={30} />
  </div>
 );
};

export default AcompanharPedido;
