import { useEffect, useState } from "react";
import { CodigoPLM } from "../Interface/ICodigoPLM";
import { getCodigoPLM } from "../Repository/domain/Servicos";

export const useTabelaQuantitativos = (
    mode: "create" | "view",
    empresa: string,
    obra: string,
    itensSelecionados: any[],
    onAtualizarItens: (itens: any[]) => void
) => {
    const [codigoPLM, setCodigoPLM] = useState<CodigoPLM[]>([]);
    const [dadosTabela, setDadosTabela] = useState<any[]>([]);

    // Busca os códigos PLM ao carregar a empresa e a obra
    useEffect(() => {
        if (empresa && obra) {
            const fetchObterCodigoPLM = async () => {
                try {
                    const codigo: CodigoPLM[] = await getCodigoPLM(empresa, obra);
                    setCodigoPLM(codigo);
                } catch (error) {
                    alert("Erro ao buscar os códigos PLM do pedido:" + error);
                }
            };
            fetchObterCodigoPLM();
        }
    }, [empresa, obra]);

    // Atualiza a tabela de dados com base nos itens selecionados
    useEffect(() => {
        if (mode === "create") {
            const novosDadosTabela = itensSelecionados.map((item) => {
                const dadosExistentes =
                    dadosTabela.find((dado) => dado.id === item.id) || {};
                return {
                    ...item,
                    codigoPLM: item.codigoPLM || dadosExistentes.codigoPLM || "",
                    quantidadeSolicitada:
                        dadosExistentes.quantidadeSolicitada || "",
                    observacoes: dadosExistentes.observacoes || "",
                };
            });
            setDadosTabela(novosDadosTabela);
        }
    }, [itensSelecionados, mode]);

    // Prevenção de loop infinito
    useEffect(() => {
        if (JSON.stringify(itensSelecionados) !== JSON.stringify(dadosTabela)) {
            onAtualizarItens(dadosTabela); // Só chamar se os dados mudarem
        }
    }, [dadosTabela]);

    // Atualiza os campos de input, incluindo a seleção de PLM
    const handleInputChange = (index: number, field: string, value: any) => {
        const newDadosTabela = [...dadosTabela];
        newDadosTabela[index] = {
            ...newDadosTabela[index],
            [field]: value,
        };
        setDadosTabela(newDadosTabela);

        if (field === "codigoPLM") {
            const plmSelecionado = codigoPLM.find(
                (codigo) => codigo.codigoPLM === value
            );
            if (plmSelecionado) {
                newDadosTabela[index].categoriaUAU = plmSelecionado.categoriaUAU;
            }
        }
        setDadosTabela(newDadosTabela);
    };

    return {
        codigoPLM,
        dadosTabela,
        setDadosTabela,
        handleInputChange,
    };
};
