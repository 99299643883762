import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import ParecerAprovacao from "../../components/ParecerAprovacao";
import ResumoContratacao from "../../components/ResumoContratacao";
import TabelaHistoricoAprovacao from "../../components/TabelaHistoricoAprovacao";
import useDetalhesDoPedido from "../../Hooks/useDetalhesDoPedido";
import useHistoricoDoPedido from "../../Hooks/useHistoricoPedido";
import { LoadingProps } from "../../Interface/ILoading";

const AprovarPedido: React.FC<LoadingProps> = ({ setLoading }) => {
 const { numeroPedido } = useParams<{ numeroPedido: string }>();
 const numeroPedidoAsNumber = parseInt(numeroPedido ?? "0", 10);
 const detalhesDoPedido = useDetalhesDoPedido(numeroPedidoAsNumber);
 const historicoDoPedido = useHistoricoDoPedido(numeroPedidoAsNumber);
 const [pedidoAtual] = useState<number>(numeroPedidoAsNumber);

 useEffect(() => {
  setLoading(true);
  if (detalhesDoPedido && historicoDoPedido) {
   setLoading(false);
  }
 }, [detalhesDoPedido, setLoading, historicoDoPedido]);

 if (detalhesDoPedido && historicoDoPedido) {
  const { pedido } = detalhesDoPedido;
  const { fornecedores } = pedido;
  const pedidoResumoContratacao = {
   codigoPedido: pedido.codigoPedido,
   codigoObra: pedido.codigoObra,
   dataAssinatura: pedido.dataAssinatura,
   descricaoServico: pedido.descricaoServico,
   detalhamento: pedido.detalhamento,
   valorContratacao: fornecedores ? fornecedores.valorContratacao : null,
   valorOrcamento: fornecedores ? fornecedores.valorOrcamento : null,
   fornecedorVencedor: fornecedores ? fornecedores.fornecedorVencedor : '',
   cnpjNomeFornecedor: fornecedores ? fornecedores.cnpjNomeFornecedor : '',
   cnpjFornecedor: fornecedores ? fornecedores.cnpjFornecedor : ''
  };
  const contrato = pedido.contratoUAU
  return (
   <div className="d-flex flex-column gap-5 w-100">
    <Navbar numeroPedido={pedidoAtual} anexoAtualizado={[]} />
    <ResumoContratacao resumoContratacao={pedidoResumoContratacao} numeroPedido={pedidoAtual} />
    <TabelaHistoricoAprovacao historicoPedido={historicoDoPedido} />
    <ParecerAprovacao idPedido={parseInt(pedido.codigoPedido)} contrato={contrato} setLoading={setLoading} />
   </div>
  );
 }
 return null;
};

export default AprovarPedido;
