import React from "react";
import { historicoPedido } from "../../Interface/IHistoricoPedido";

interface TabelaHistoricoAprovacaoProps {
 historicoPedido: historicoPedido[];
}

const TabelaHistoricoAprovacao: React.FC<TabelaHistoricoAprovacaoProps> = ({
 historicoPedido,
}) => {
 const formatarEtapa = (etapa: string) => {
  return etapa
   .replace(/-?\s*Suprimentos.*/i, "")
   .replace(/-?\s*Aprovador.*/i, "")
   .trim();
 };

 return (
  <>
   <h5>Histórico de aprovação</h5>
   <table className="table table-striped text-center table-hover table-bordered border-lightinfo">
    <thead>
     <tr className="table-primary text-center">
      <th scope="col">Etapa</th>
      <th scope="col">Responsável</th>
      <th scope="col">Status</th>
      <th scope="col">Justificativa</th>
      <th scope="col">Usuário</th>
      <th scope="col">Data</th>
     </tr>
    </thead>
    <tbody>
     {historicoPedido.map((historico, index) => (
      <tr key={index}>
       <td id="Etapa"> {formatarEtapa(historico.etapa)} </td>
       <td id="Responsavel"> {historico.responsavelEtapa || "-"} </td>
       <td id="Aprovacao"> {historico.aprovacao} </td>
       <td id="Justificativa"> {historico.justificativa || "-"} </td>
       <td id="Usuario"> {historico.usuario} </td>
       <td id="Data"> {historico.dataMovimentacao} </td>
      </tr>
     ))}
    </tbody>
   </table>
  </>
 );
};

export default TabelaHistoricoAprovacao;
