import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CriarPedido from "../Pages/CriarPedido";
import AcompanharPedido from "../Pages/AcompanharPedido";
import VisualizarPedidos from "../Pages/VisualizarPedido";
import AprovarPedido from "../Pages/AprovarPedido";
import Login from "../Pages/Login";
import { LoadingProps } from "../Interface/ILoading";

const RoutesComponent: React.FC<LoadingProps> = ({ setLoading }) => {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login/:tela/:tokenCognito/:tokenGA/:accessToken"
          element={<Login setLoading={setLoading} />}
        />
        <Route
          path="/solicitar/"
          element={<CriarPedido setLoading={setLoading} />}
        />
        <Route
          path="/pedidos/"
          element={<AcompanharPedido setLoading={setLoading} />}
        />
        <Route
          path="/visualizarPedido/:numeroPedido"
          element={<VisualizarPedidos setLoading={setLoading} />}
        />
        <Route
          path="/aprovacoes/:numeroPedido"
          element={<AprovarPedido setLoading={setLoading} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
